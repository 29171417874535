import axios from "axios";

// ** Product methods **
const config = {
  method: "get",
  maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_SERVER_API_URL}`,
  headers: {
    "X-API-KEY": `${process.env.REACT_APP_SERVER_API_KEY}`,
  },
};

export async function getAllProducts() {
  try {
    config.url += "/product";
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}
export async function getProductById(id) {
  try {
    config.url += "/product/" + id;
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}

// ** Gift card methods **

export async function getAllGiftCards() {
  try {
    config.url += "/gift-card/";
    const response = await axios.request(config);
    return response.data.map((gift) => {
      return {
        ...gift,
        name: JSON.parse(gift.name),
        description: JSON.parse(gift.description),
      };
    });
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}

export async function getCustomerInvoice(customerId) {
  try {
    config.url += `/stripe/invoicesList/${customerId}`;
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}
