import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const apiKey = "FualmHIwXsMJD2xN0vIqFw";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'sv', // <--- turn off for detection to work
    fallbackLng: "sv",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: ["sv", "en"],

    preload: false,

    backend: {
      loadPath: loadPath
    },
    debug: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      // order and from where user language should be detected
      // more info check doc of this https://github.com/i18next/i18next-browser-languageDetector
      order: ['path', 'localStorage', 'navigator', ],

      // keys or params to lookup language from
      lookupFromPathIndex: 0,
    },

  })