import React from 'react'

import { withFirebase } from '../Firebase'

import { useTranslation } from "react-i18next"

const SignOutButton = ({ firebase, icon = '' }) => {
  const { t, i18n } = useTranslation()
  const baseUrl = i18n.language === 'sv' ? '' : '/' + i18n.language;

  const signOutFunc = () => {
    firebase.auth.signOut()
    window.location = baseUrl + '/'
  }
  return (
    <button type='button' onClick={signOutFunc}>
      {t('Logga ut')} {icon}
    </button>
  )
}

export default withFirebase(SignOutButton)
