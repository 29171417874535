import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Navigation from "../Navigation";
import ThankyouPage from "../ThankYou";
import SubscribePage from "../Subscribe";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import PasswordChangePage from "../PasswordChange";
import EmailChangePage from "../EmailChange";
import GiftCardPage from "../GiftCard";
import GiftCardRedeem from "../GiftCard/Redeem";
import GiftCardConfirm from "../GiftCard/Confrim";

// import HomePage from '../Home';
import PaymentPage from "../Payment";
import AccountPage from "../Account";
import AccountPaymentPage from "../AccountPayment";
import PageNotFound from "../404";

import Yearly100Page from "../Subscribe/yearly-100";
import QuarterPage from "../Subscribe/quarter";
import MonthlyPage from "../Subscribe/monthly";
import MonthlyOffer1Page from "../Subscribe/monthly-offer1";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

// import BgImage from '../../imgs/Bakgrund-checkout.jpg'

import { useTranslation } from "react-i18next";
import { withFirebase } from "../Firebase";

const App = () => {
  return (
    <BrowserRouter>
      <AppHtml />
    </BrowserRouter>
  );
};

const AppHtmlBase = (props) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [baseUrl, setBaseUrl] = useState(
    i18n.language === "sv" ? "" : "/" + i18n.language
  );
  const [loginClass, SetLoginClass] = useState("homepage");

  // check here current page is being visited via loggedIn users
  const currentUser = props.firebase.auth.currentUser;

  useEffect(() => {
    setBaseUrl(i18n.language === "sv" ? "" : "/" + i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    let loginClass = ["rootContainer"];

    if (currentUser) {
      loginClass.push("loggedIn");
    }

    if (pathname === "/" || pathname === "/en") {
      loginClass.push("homepage");
    } else {
      let pageName = pathname.substring(1);
      if (pageName.includes("en/")) pageName = pageName.substring(3);
      if (pageName.substring(pageName.length - 1) === "/") {
        pageName = pageName.substring(0, pageName.length - 1);
      }
      if (pageName.includes("/")) {
        pageName = pageName.replace("/", "-");
      }
      loginClass.push(pageName + "-page");
    }
    SetLoginClass(loginClass.join(" "));
  }, [currentUser, pathname]);

  const BgImageObj = {};
  // BgImageObj.backgroundImage = 'linear-gradient(180deg, #F2A1B0 0%, #FDECF4 77%)' // 'url(' + BgImage + ')'

  return (
    <div className={loginClass} style={{ ...BgImageObj }}>
      <Navigation />
      <Routes>
        {/* <hr /> */}
        <Route path={baseUrl + ROUTES.THANKYOU} element={<ThankyouPage />} />
        <Route path={baseUrl + ROUTES.SUBSCRIBE} element={<SubscribePage />} />
        <Route path={baseUrl + ROUTES.Yearly100} element={<Yearly100Page />} />
        <Route path={baseUrl + ROUTES.Quarter} element={<QuarterPage />} />
        <Route path={baseUrl + ROUTES.Monthly} element={<MonthlyPage />} />
        <Route path={baseUrl + ROUTES.MonthlyOffer1} element={<MonthlyOffer1Page />} />

        <Route path={baseUrl + ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path={baseUrl + "/"} element={<SignInPage />} />
        <Route path={baseUrl + ROUTES.SIGN_UP} element={<SignUpPage />} />
        <Route
          path={baseUrl + ROUTES.PASSWORD_FORGET}
          element={<PasswordForgetPage />}
        />
        <Route
          path={baseUrl + ROUTES.PASSWORD_CHANGE}
          element={<PasswordChangePage />}
        />
        <Route
          path={baseUrl + ROUTES.EMAIL_CHANGE}
          element={<EmailChangePage />}
        />
        <Route
          path={baseUrl + ROUTES.Gift_card_page}
          element={<GiftCardPage />}
        />
        <Route
          path={baseUrl + ROUTES.Gift_card_redeem}
          element={<GiftCardRedeem />}
        />
        <Route
          path={baseUrl + ROUTES.Gift_card_confirm}
          element={<GiftCardConfirm />}
        />

        {/* <Route path={baseUrl + ROUTES.HOME} element={<HomePage />} /> */}
        <Route path={baseUrl + ROUTES.PAYMENT} element={<PaymentPage />} />
        <Route path={baseUrl + ROUTES.ACCOUNT} element={<AccountPage />} />
        <Route
          path={baseUrl + ROUTES.ACCOUNT_PAYMENT}
          element={<AccountPaymentPage />}
        />

        {/* redirect to 404 page  */}
        <Route path={baseUrl + ROUTES.NOTFOUND} element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

const AppHtml = withFirebase(AppHtmlBase);
export default withAuthentication(App);
