import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { compose } from "recompose";

import { AuthUserContext } from "../Session";

import { SignInFacebook, SignInGoogle, updateUserDataInDB } from "../SignIn";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import { BlancheLogoRed } from "../Icons";
import { LanguageSwitcher } from "../Navigation";

import { Trans, useTranslation } from "react-i18next";

import { ErrorsObj, linkStyle } from "../common";

const policyTextStyle = {
  width: "100%",
  maxWidth: "273px",
  marginBottom: "16px",
  fontSize: "14px",
  display: "block",
  margin: "auto",
  paddingBottom: "40px",
  letterSpacing: ".2px",
};

const SignUpPage = () => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  const navigate = useNavigate();

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          navigate(baseUrl + ROUTES.ACCOUNT)
        ) : (
          <div className="landingPage signin-page">
            <div className="centerContent logoSection">
              <div style={{ width: "100%" }}>
                <BlancheLogoRed className="logo" />

                <LanguageSwitcher list={true} />
              </div>
            </div>

            <div className="centerContent">
              <div className="sectionContent pkfullw">
                <h1 className="sectionTitle">{t("create_acc_heading")}</h1>
                <p>{t("Kom och låt våra stories förföra dig")}</p>
                <SignUpForm route={baseUrl + ROUTES.PAYMENT} />
                <SignInFacebook
                  route={baseUrl + ROUTES.PAYMENT}
                  title={t("login_with", { lw: "Facebook" })}
                />
                <SignInGoogle
                  route={baseUrl + ROUTES.PAYMENT}
                  title={t("login_with", { lw: "Google" })}
                />
                <p>
                  {t("Har du redan ett konto")}{" "}
                  <Link
                    to={baseUrl + ROUTES.SIGN_IN}
                    reloadDocument
                    style={linkStyle}
                  >
                    {t("login_btn")}
                  </Link>
                </p>
                <p style={policyTextStyle}>
                  <Trans i18nKey="terms_privacy_text">
                    Genom att fortsätta godkänner du våra{" "}
                    <a
                      href="https://blanchestories.com/terms-of-use/"
                      style={linkStyle}
                    >
                      Användarvillkor
                    </a>{" "}
                    och vår{" "}
                    <a
                      href="https://blanchestories.com/privacy-policy/"
                      style={linkStyle}
                    >
                      Sekretesspolicy
                    </a>
                    .
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        )
      }
    </AuthUserContext.Consumer>
  );
};

const INITIAL_STATE = {
  displayname: "",
  email: "",
  passwordOne: "",
  // passwordTwo: '',
};

const SignUpFormBase = (props) => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onSubmit = (event) => {
    const { email, passwordOne } = userInfo;
    // console.log('HELLO');

    props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(async (authUser) => {
        // Create a user in your Firebase Cloud Database too
        updateUserDataInDB(props, authUser.user.uid, {
          uid: authUser.user.uid,
          displayname: userInfo.displayname || authUser.user.displayName,
          email: authUser.user.email,
        });

        // console.log('CREATED', authUser, props.route)
        navigate(props.route);
      })
      .then(() => {
        setUserInfo(INITIAL_STATE);
        setError(null);
      })
      .catch((error) => {
        if (ErrorsObj.hasOwnProperty(error.code)) {
          error.message = ErrorsObj[error.code];
        }

        setError(error);
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  // const onChangeCheckbox = (event) => {
  //   const { name, checked } = event.target;
  //   setUserInfo({...userInfo, [name]: checked})
  // };

  const {
    email,
    passwordOne,
    // passwordTwo,
  } = userInfo;

  const isInvalid =
    // passwordOne !== passwordTwo ||
    passwordOne === "" || email === "";

  return (
    <form onSubmit={onSubmit} className="app-form signup-form">
      {/* <input
        name='username'
        value={username}
        onChange={onChange}
        type='text'
        placeholder='Full Name'
        className='inputStyle'
      /> */}
      <input
        name="displayname"
        value={userInfo.displayname}
        onChange={onChange}
        type="text"
        placeholder={t("name")}
        className="inputStyle"
        required
      />
      <input
        name="email"
        value={email}
        onChange={onChange}
        type="text"
        placeholder={t("email_lbl")}
        className="inputStyle"
      />
      <input
        name="passwordOne"
        value={passwordOne}
        onChange={onChange}
        type="password"
        placeholder={t("passw_lbl")}
        className="inputStyle"
      />
      {/* <input
        name='passwordTwo'
        value={passwordTwo}
        onChange={onChange}
        type='password'
        placeholder='Bekräfta lösenord'
        className='inputStyle'
      /> */}
      <input type="submit" style={{ display: "none" }} />
      <button disabled={isInvalid} type="submit" className="buttonStyle">
        {t("create_acc_btn")}
      </button>

      {error && <p className="errorMessage">{t(error.message)}</p>}
    </form>
  );
};

const SignUpLink = () => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  return (
    <p style={{ letterSpacing: ".2px" }}>
      {t("Är du ny här?")}{" "}
      <Link to={baseUrl + ROUTES.SUBSCRIBE} reloadDocument style={linkStyle}>
        {t("create_acc_btn")}
      </Link>
    </p>
  );
};

const withRouter = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
