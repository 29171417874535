import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { updateUserDataInDB } from "../SignIn";
import { PasswordForgetLink } from "../PasswordForget";
import { BlancheLogoRed, GoogleIcon } from "../Icons";
import * as ROUTES from "../../constants/routes";

import { Trans, useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../Navigation";

import { linkStyle, loginButtonStyle, ErrorsObj, STRIPE_API_KEY, plans, promoCodes, taxRates } from "../common";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SubscribePage = (props) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;
  const navigate = useNavigate();
  // logout
  // props.firebase.auth.signOut()

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    uid: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false); // user is currently login ?
  const [isLoginProcess, setIsLoginProcess] = useState(false); // user is going to login or signup ?
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    let isMounted = true;

    if (props.firebase.auth.currentUser && isMounted) {
      userInfo.uid = props.firebase.auth.currentUser.uid;
      userInfo.email = props.firebase.auth.currentUser.email;

      setIsLoggedIn(true);
    }

    return () => {
      isMounted = false;
    };
  }, [props.firebase.auth.currentUser, userInfo]);

  /**
   * if user already have a subscription plan then redirect to account manage page
   */
  useEffect(() => {
    if (props.firebase.userSubscription) {
      navigate(ROUTES.PAYMENT);
    }
  }, [props.firebase.userSubscription, navigate]);

  const verifyLoadCheckout = async (planId) => {
    if (!userInfo.uid) return;

    // if user login then redirect on  Payments page
    if (props.firebase.userSubscription) {
      console.log("firebase.userSubscription", props.firebase.userSubscription);
      navigate(ROUTES.PAYMENT);
      return;
    }

    const price = planId || selectedPlan;
    if (!price) {
      catchError({ message: 'Select plan first' });
      return;
    }

    setOpenModal(false);

    setIsLoading(true);

    // Create PaymentIntent as soon as the page loads
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-API-KEY", process.env.REACT_APP_SERVER_API_KEY);
    await fetch(
      process.env.REACT_APP_SERVER_API_URL + "/stripe/createCheckoutSession",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: userInfo.email,
          price: price,
          taxRate: taxRates,
          allowPromotionCodes: true,
          successUrl: window.location.origin + "/thankyou",
          cancelUrl: window.location.origin + "/subscribe/monthly-offer1",
          mode: "subscription",
          promoCode: promoCodes.month1,
          trial: 0,
        }),
      }
    )
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then(async (session) => {
        console.log("session", session);

        if (session.id) {
          // Make sure to call `loadStripe` outside of a component’s render to avoid
          // recreating the `Stripe` object on every render.
          const stripePromise = await loadStripe(
            STRIPE_API_KEY
          );
          stripePromise.redirectToCheckout({ sessionId: session.id });
        }

        // setIsLoading(false); // hide loader
      })
      .catch((error) => {
        setIsLoading(false); // hide loader
        if (error.message) {
          catchError(error);
        } else {
          error.json().then((error) => {
            if (error.code === "resource_missing")
              error.code = "stripe_resource_missing";
            catchError(error);
          });
        }
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((userInfo) => ({ ...userInfo, [name]: value }));
  };

  const updateLoginProcess = (event) => {
    event.preventDefault();
    setIsLoginProcess(!isLoginProcess);
  };

  const catchError = (error) => {
    console.log("error", error);
    if (ErrorsObj.hasOwnProperty(error.code)) {
      error.message = ErrorsObj[error.code];
    }

    setError(error);
  };

  const submitUser = (event) => {
    event.preventDefault();
    setIsLoading(true); // show loader

    var { email, password } = userInfo;

    if (isLoginProcess) {
      props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(async (authUser) => {
          userInfo.uid = authUser.user.uid;

          // Create a user in your Firebase Cloud Database too
          await updateUserDataInDB(props, userInfo.uid, {
            emailLang: i18n.language === "sv" ? "sw" : "en",
          });

          await verifyLoadCheckout();
        })
        .catch((error) => {
          setIsLoading(false); // hide loader
          catchError(error);
        });
    } else {
      props.firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(async (authUser) => {
          // Create a user in your Firebase realtime database
          // console.log('CREATED', authUser, props.route)
          userInfo.uid = authUser.user.uid;

          await updateUserDataInDB(props, userInfo.uid, {
            uid: userInfo.uid,
            email: email,
            displayname: userInfo.displayname,
            emailLang: i18n.language === "sv" ? "sw" : "en",
          });

          await verifyLoadCheckout();

          // setUserInfo(INITIAL_STATE)
          setError(null);
        })
        .catch((error) => {
          setIsLoading(false); // hide loader
          catchError(error);
        });
    }
  };

  const googleLogin = (event) => {
    setIsLoading(true); // show loader
    props.firebase
      .doSignInWithGoogle()
      .then(async (socialAuthUser) => {
        // console.log('socialAuthUser', socialAuthUser)
        userInfo.uid = socialAuthUser.user.uid;
        userInfo.email = socialAuthUser.user.email;

        // Create a user in your Firebase Cloud Database too
        await updateUserDataInDB(props, userInfo.uid, {
          uid: userInfo.uid,
          displayname: userInfo.displayname || socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          emailLang: i18n.language === "sv" ? "sw" : "en",
        });

        await verifyLoadCheckout();

        setError(null);
      })
      .catch((error) => {
        catchError(error);
        setIsLoading(false); // hide loader
      });

    event.preventDefault();
  };

  const buyPlan = async (planId) => {
    setSelectedPlan(planId);
    if (!isLoggedIn) {
      setOpenModal(true);
      return;
    }
    verifyLoadCheckout(planId);
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="landingPage newTemplate">
          <div className="centerContent logoSection">
            <div style={{ width: "100%" }}>
              <BlancheLogoRed className="logo" />

              <LanguageSwitcher list={true} />
            </div>
          </div>

          <div className="centerContent">
            <h1 style={{ margin: "0 auto -30px", fontSize: "35px", textAlign: 'center' }}>
              {t("BLANCHEstories hetaste deal")}
              <br />
              {t("tre månader Hot romance GRATIS!")}
            </h1>
          </div>

          <div className="centerContent">
            <div className="sectionContent">
              <div className="innerSection">
                <div className="titleSection">
                  <h2 className="planName">3 {t("Månader")}</h2>
                  <h3 className="planName2">{t("Obegränsad lysnning")}</h3>
                </div>

                <div
                  className="decsSection"
                  style={{ minHeight: "auto", paddingBottom: "10px" }}
                >
                  <h3
                    className="planPrice"
                    style={{ fontSize: "34px", color: "grey" }}
                  >
                    <del>{t("79 sek / mån")}</del>
                  </h3>
                  <h3 className="planPrice">{t("0 sek")}</h3>

                  <div className="subsPoints">
                    <ul>
                      <li>
                        <span>{t("550+ tonsatta originalstories")}</span>
                      </li>

                      <li>
                        <span>{t("Noveller på svenska och engelska")}</span>
                      </li>

                      <li>
                        <span>{t("En reklamfri upplevelse")}</span>
                      </li>

                      <li>
                        <span>{t("Nya släpp varje vecka")}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footerSection">
                  <button type="button" onClick={() => buyPlan(plans.month)}>
                    <span className="button-text">
                      {(isLoading && selectedPlan === plans.month && (
                        <div className="spinner"></div>
                      )) ||
                        t("Starta Abonnemang")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="centerContent" style={{ marginTop: "-60px" }}>
            <div
              className="sectionContent lowerContent"
              style={{ minHeight: "auto" }}
            >
              {error && (
                <p className="errorMessage" style={{ marginTop: '30px', maxWidth: '450px' }}>
                  {t(error.message)}
                </p>
              )}

              <h2 className="">
                <small>{t('Efter din gratis period övergår abonnemanget till 79kr / månad.')}</small>
              </h2>

              <h2 className="">
                {t('Ingen bindningstid - Avsluta eller byt abonnemang när du vill.')}
              </h2>
            </div>
          </div>

          <div className={`popupSection ${openModal ? "" : "hide"}`}>
            <div
              className="popupOverlay"
              onClick={() => setOpenModal(false)}
            ></div>

            <div className="popupContent">
              <form method="POST" action="" onSubmit={submitUser}>
                <h2 className="sectionTitle">
                  {isLoginProcess
                    ? t("Välkommen tillbaka")
                    : t("Kom igång med ett konto")}
                </h2>

                {!isLoginProcess && (
                  <input
                    name="displayname"
                    value={userInfo.displayname}
                    onChange={onChange}
                    type="text"
                    placeholder={t("name")}
                    className="inputStyle"
                    required
                  />
                )}

                <input
                  name="email"
                  value={userInfo.email}
                  onChange={onChange}
                  type="email"
                  placeholder={t("email_lbl")}
                  className="inputStyle"
                  required
                />

                <label style={{ display: 'block', position: 'relative' }}>
                  <input
                    name="password"
                    value={userInfo.password}
                    onChange={onChange}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("passw_lbl")}
                    className="inputStyle"
                  />
                  {!showPassword && <span onClick={() => setShowPassword(true)}><Visibility style={{ position: 'absolute', right: '10px', top: '13.5px' }}></Visibility></span>}
                  {showPassword && <span onClick={() => setShowPassword(false)}><VisibilityOff style={{ position: 'absolute', right: '10px', top: '13.5px' }}></VisibilityOff></span>}
                </label>

                {error && (
                  <p className="errorMessage">
                    {error.message === "email-already-in-use" ? (
                      <Trans i18nKey="email-already-in-use">
                        Mejladressen finns redan registrerad. Du kan
                        <a href={baseUrl + "/"} style={linkStyle}>
                          logga in
                        </a>
                        eller
                        <a
                          href={
                            baseUrl +
                            ROUTES.PASSWORD_FORGET +
                            `${userInfo.email ? "?email=" + userInfo.email : ""
                            }`
                          }
                          style={linkStyle}
                        >
                          återställa lösenordet
                        </a>
                        .
                      </Trans>
                    ) : (
                      <>{t(error.message)}</>
                    )}
                  </p>
                )}

                {error && <PasswordForgetLink email={userInfo.email} />}

                <p style={{ fontSize: "18px", wordBreak: "break-word" }}>
                  Genom att fortsätta godkänner du våra{" "}
                  <a
                    href="https://blanchestories.com/terms-of-use/"
                    style={linkStyle}
                  >
                    Användarvillkor
                  </a>{" "}
                  och vår{" "}
                  <a
                    href="https://blanchestories.com/privacy-policy/"
                    style={linkStyle}
                  >
                    Sekretesspolicy
                  </a>
                  .
                </p>

                <button type="submit" className="buttonStyle socialButton">
                  <span className="button-text">
                    {isLoading ? (
                      <div className="spinner"></div>
                    ) : isLoginProcess ? (
                      t("login_btn")
                    ) : (
                      t("create_acc_btn")
                    )}
                  </span>
                </button>

                <button
                  type="submit"
                  className="buttonStyle socialButton"
                  onClick={(e) => {
                    if (isLoginProcess || userInfo.displayname) {
                      e.preventDefault();
                      googleLogin();
                      return false;
                    }
                  }}
                >
                  <GoogleIcon style={{ marginRight: "8px" }} />
                  {t(isLoginProcess ? "login_with" : "acc_create_with", {
                    lw: "Google",
                  })}
                </button>
              </form>

              {/* when user not logged, show signup form */}
              {!isLoginProcess && (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Har du redan ett konto?")}{" "}
                  <button
                    type="button"
                    style={loginButtonStyle}
                    onClick={updateLoginProcess}
                  >
                    {t("login_btn")}
                  </button>
                </p>
              )}

              {/* otherwise show signin form */}
              {isLoginProcess && (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Är du ny här?")}{" "}
                  <button
                    type="button"
                    style={loginButtonStyle}
                    onClick={updateLoginProcess}
                  >
                    {t("create_acc_btn")}
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(SubscribePage);
