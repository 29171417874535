import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm";

import { loadStripe } from "@stripe/stripe-js";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { updateUserDataInDB } from "../SignIn";
import { PasswordForgetLink } from "../PasswordForget";
import { BlancheLogoRed, GoogleIcon, UlListIcon } from "../Icons";
import * as ROUTES from "../../constants/routes";

import { Trans, useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../Navigation";

import {
  linkStyle,
  buttonLinkStyle,
  loginButtonStyle,
  taxRates,
  ErrorsObj,
  STRIPE_API_KEY,
} from "../common";

import { getAllGiftCards } from "../../apis";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// check in CMS
const plans = {
  year: "7319b382-87ef-4a6b-a071-1f44fcc4d5ce", // Gift Card - One Years Subscription
  month: "0bdb9c55-18fa-461a-a570-e54b07a35bee", // Gift Card - One Months Subscription
};

const GiftCard = (props) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    uid: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false); // user is currently login ?
  const [isLoginProcess, setIsLoginProcess] = useState(false); // user is going to login or signup ?
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGiftCard, setSelectedGiftCard] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [giftCards, setGiftCards] = useState([]);
  const [showPassword, setShowPassword] = useState(false)

  /**
   *fetch all available GiftCards
   */
  /* useEffect(() => {
    const fetchGifts = async () => {
      if (giftCards.length > 0) return;

      const res = await getAllGiftCards();

      setGiftCards(res);
    };

    fetchGifts();
  }, [giftCards]); */

  useEffect(() => {
    window.location = 'https://blanchestories.com/presentkort/'
    return;
    let isMounted = true;

    if (props.firebase.auth.currentUser && isMounted) {
      userInfo.uid = props.firebase.auth.currentUser.uid;
      userInfo.email = props.firebase.auth.currentUser.email;

      setIsLoggedIn(true);
    }

    return () => {
      isMounted = false;
    };
  }, [props.firebase.auth.currentUser, userInfo]);

  const verifyLoadCheckout = async (giftCardId) => {
    if (!userInfo.uid) return;

    setOpenModal(false);

    setIsLoading(true);

    // Create PaymentIntent as soon as the page loads
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-API-KEY", process.env.REACT_APP_SERVER_API_KEY);
    await fetch(
      process.env.REACT_APP_SERVER_API_URL + "/stripe/createCheckoutSession",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: userInfo.email,
          price: giftCardId || selectedGiftCard || null,
          successUrl: window.location.origin + "/thankyou",
          cancelUrl: window.location.origin + "/gift-card-page",
          mode: "payment",
        }),
      }
    )
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then(async (session) => {
        console.log("session", session);

        if (session.id) {
          // Make sure to call `loadStripe` outside of a component’s render to avoid
          // recreating the `Stripe` object on every render.
          const stripePromise = await loadStripe(
            STRIPE_API_KEY
          );
          stripePromise.redirectToCheckout({ sessionId: session.id });
        }

        // setIsLoading(false); // hide loader
      })
      .catch((error) => {
        setIsLoading(false); // hide loader
        if (error.message) {
          catchError(error);
        } else {
          error.json().then((error) => {
            if (error.code === "resource_missing")
              error.code = "stripe_resource_missing";
            catchError(error);
          });
        }
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((userInfo) => ({ ...userInfo, [name]: value }));
  };

  const updateLoginProcess = (event) => {
    event.preventDefault();
    setIsLoginProcess(!isLoginProcess);
  };

  const catchError = (error) => {
    console.log("error", error);
    if (ErrorsObj.hasOwnProperty(error.code)) {
      error.message = ErrorsObj[error.code];
    }

    setError(error);
  };

  const submitUser = (event) => {
    event.preventDefault();
    setIsLoading(true); // show loader

    var { email, password } = userInfo;

    if (isLoginProcess) {
      props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(async (authUser) => {
          userInfo.uid = authUser.user.uid;

          // Create a user in your Firebase Cloud Database too
          await updateUserDataInDB(props, userInfo.uid, {
            emailLang: i18n.language === "sv" ? "sw" : "en",
          });

          await verifyLoadCheckout();
        })
        .catch((error) => {
          setIsLoading(false); // hide loader
          catchError(error);
        });
    } else {
      props.firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(async (authUser) => {
          // Create a user in your Firebase realtime database
          // console.log('CREATED', authUser, props.route)
          userInfo.uid = authUser.user.uid;

          await updateUserDataInDB(props, userInfo.uid, {
            uid: userInfo.uid,
            email: email,
            displayname: userInfo.displayname,
            emailLang: i18n.language === "sv" ? "sw" : "en",
          });

          await verifyLoadCheckout();

          // setUserInfo(INITIAL_STATE)
          setError(null);
        })
        .catch((error) => {
          setIsLoading(false); // hide loader
          catchError(error);
        });
    }
  };

  const googleLogin = (event) => {
    setIsLoading(true); // show loader
    props.firebase
      .doSignInWithGoogle()
      .then(async (socialAuthUser) => {
        // console.log('socialAuthUser', socialAuthUser)
        userInfo.uid = socialAuthUser.user.uid;
        userInfo.email = socialAuthUser.user.email;

        // Create a user in your Firebase Cloud Database too
        await updateUserDataInDB(props, userInfo.uid, {
          uid: userInfo.uid,
          displayname: userInfo.displayname || socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          emailLang: i18n.language === "sv" ? "sw" : "en",
        });

        await verifyLoadCheckout();

        setError(null);
      })
      .catch((error) => {
        catchError(error);
        setIsLoading(false); // hide loader
      });

    event.preventDefault();
  };

  const buyPlan = async (giftCardId) => {
    setSelectedGiftCard(giftCardId);
    if (!isLoggedIn) {
      setOpenModal(true);
      return;
    }
    verifyLoadCheckout(giftCardId);
  };

  return '' // implemented this bcoz OnLoad, page is redirecting to another url

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="landingPage newTemplate">
          {!authUser && (
            <div className="centerContent logoSection">
              <div style={{ width: "100%" }}>
                <BlancheLogoRed className="logo" />

                <LanguageSwitcher list={true} />
              </div>
            </div>
          )}

          <div className="centerContent" style={{ marginBottom: 0, maxWidth: '800px' }}>
            {giftCards.map((giftCard, index) => {
              let name = giftCard.name.en;
              let description = giftCard.description.en;
              if (i18n.language === "sv") {
                name = giftCard.name.sw;
                description = giftCard.description.sw;
              }

              return (
                <div className="sectionContent" key={index}>
                  <div className="innerSection">
                    <div className="titleSection">
                      <h2 className="planName">{name}</h2>
                      <p className="planName2">{t("Obegränsad lysnning")}</p>
                    </div>

                    <div className="decsSection">
                      {/* <p className="planTrial">{t("14 dagar gratis")}</p> */}
                      <h3 className="planPrice">{t("79 sek / mån")}</h3>

                      <div className="subsPoints">
                        <ul>
                          <li>
                            <span>{t("550+ tonsatta originalstories")}</span>
                          </li>

                          <li>
                            <span>{t("Noveller på svenska och engelska")}</span>
                          </li>

                          <li>
                            <span>{t("En reklamfri upplevelse")}</span>
                          </li>

                          <li>
                            <span>{t("Nya släpp varje vecka")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="footerSection">
                      <button
                        type="button"
                        onClick={() => buyPlan(giftCard.id)}
                      >
                        <span className="button-text">
                          {(isLoading && selectedGiftCard === giftCard.id && (
                            <div className="spinner"></div>
                          )) ||
                            t("Buy Now")}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="sectionContent">
              <div className="innerSection">
                <div className="titleSection">
                  <h2 className="planName">{t("1 MÅNAD Gift Card")}</h2>
                  <p className="planName2">{t("Obegränsad lysnning")}</p>
                </div>

                <div className="decsSection">
                  {/* <p className="planTrial">{t("14 dagar gratis")}</p> */}
                  <h3 className="planPrice">{t("79 sek / mån")}</h3>

                  <div className="subsPoints">
                    <ul>
                      <li>
                        <span>{t("En reklamfri upplevelse")}</span>
                      </li>

                      <li>
                        <span>{t("550+ tonsatta originalstories")}</span>
                      </li>

                      <li>
                        <span>{t("Noveller på svenska och engelska")}</span>
                      </li>

                      <li>
                        <span>{t("Nya släpp varje vecka")}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footerSection">
                  <button type="button" onClick={() => buyPlan(plans.month)}>
                    <span className="button-text">
                      {(isLoading && selectedGiftCard === plans.month && (
                        <div className="spinner"></div>
                      )) ||
                        t("Buy Now")}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="sectionContent ">
              <div className="innerSection">
                <div className="titleSection">
                  <h2 className="planName">{t("1 ÅR Gift Card")}</h2>
                  <h3 className="planName2">{t("Obegränsad lysnning")}</h3>
                </div>

                <div className="decsSection">
                  {/* <h3 className="planTrial">{t("14 dagar gratis")}</h3> */}
                  <h3 className="planPrice">{t("595 sek / år")}</h3>

                  <div className="subsPoints">
                    <ul>
                      <li>
                        <span>{t("En reklamfri upplevelse")}</span>
                      </li>

                      <li>
                        <span>{t("550+ tonsatta originalstories")}</span>
                      </li>

                      <li>
                        <span>{t("Noveller på svenska och engelska")}</span>
                      </li>

                      <li>
                        <span>{t("Nya släpp varje vecka")}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footerSection">
                  <button type="button" onClick={() => buyPlan(plans.year)}>
                    <span className="button-text">
                      {(isLoading && selectedGiftCard === plans.year && (
                        <div className="spinner"></div>
                      )) ||
                        t("Buy Now")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="centerContent">
            <div className="sectionContent lowerContent">
              <h2 className="" style={{ fontSize: "35px" }}>
                {t('Already have a gift card?')}
              </h2>
              <button
                type="button"
                style={buttonLinkStyle}
                onClick={() => navigate(baseUrl + ROUTES.Gift_card_redeem)}
              >
                {t("Click here to redeem")}
              </button>
            </div>
          </div>
          <div className={`popupSection ${openModal ? "" : "hide"}`}>
            <div
              className="popupOverlay"
              onClick={() => setOpenModal(false)}
            ></div>

            <div className="popupContent">
              <form method="POST" action="" onSubmit={submitUser}>
                <h2 className="sectionTitle">
                  {isLoginProcess
                    ? t("Välkommen tillbaka")
                    : t("Kom igång med ett konto")}
                </h2>

                {!isLoginProcess && (
                  <input
                    name="displayname"
                    value={userInfo.displayname}
                    onChange={onChange}
                    type="text"
                    placeholder={t("name")}
                    className="inputStyle"
                    required
                  />
                )}

                <input
                  name="email"
                  value={userInfo.email}
                  onChange={onChange}
                  type="email"
                  placeholder={t("email_lbl")}
                  className="inputStyle"
                  required
                />

                <label style={{ display: 'block', position: 'relative' }}>
                  <input
                    name="password"
                    value={userInfo.password}
                    onChange={onChange}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("passw_lbl")}
                    className="inputStyle"
                  />
                  {!showPassword && <span onClick={() => setShowPassword(true)}><Visibility style={{ position: 'absolute', right: '10px', top: '13.5px' }}></Visibility></span>}
                  {showPassword && <span onClick={() => setShowPassword(false)}><VisibilityOff style={{ position: 'absolute', right: '10px', top: '13.5px' }}></VisibilityOff></span>}
                </label>

                {error && (
                  <p className="errorMessage">
                    {error.message === "email-already-in-use" ? (
                      <Trans i18nKey="email-already-in-use">
                        Mejladressen finns redan registrerad. Du kan{" "}
                        <a href={baseUrl + "/"} style={linkStyle}>
                          logga in
                        </a>{" "}
                        eller{" "}
                        <a
                          href={
                            baseUrl +
                            ROUTES.PASSWORD_FORGET +
                            `${userInfo.email ? "?email=" + userInfo.email : ""
                            }`
                          }
                          style={linkStyle}
                        >
                          återställa lösenordet
                        </a>
                        .
                      </Trans>
                    ) : (
                      <>{t(error.message)}</>
                    )}
                  </p>
                )}

                {error && <PasswordForgetLink email={userInfo.email} />}

                <p style={{ fontSize: "18px", wordBreak: "break-word" }}>
                  Genom att fortsätta godkänner du våra{" "}
                  <a
                    href="https://blanchestories.com/terms-of-use/"
                    style={linkStyle}
                  >
                    Användarvillkor
                  </a>{" "}
                  och vår{" "}
                  <a
                    href="https://blanchestories.com/privacy-policy/"
                    style={linkStyle}
                  >
                    Sekretesspolicy
                  </a>
                  .
                </p>

                <button type="submit" className="buttonStyle socialButton">
                  <span className="button-text">
                    {isLoading ? (
                      <div className="spinner"></div>
                    ) : isLoginProcess ? (
                      t("login_btn")
                    ) : (
                      t("create_acc_btn")
                    )}
                  </span>
                </button>

                <button
                  type="submit"
                  className="buttonStyle socialButton"
                  onClick={(e) => {
                    if (isLoginProcess || userInfo.displayname) {
                      e.preventDefault();
                      googleLogin();
                      return false;
                    }
                  }}
                >
                  <GoogleIcon style={{ marginRight: "8px" }} />
                  {t(isLoginProcess ? "login_with" : "acc_create_with", {
                    lw: "Google",
                  })}
                </button>
              </form>

              {/* when user not logged, show signup form */}
              {!isLoginProcess && (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Har du redan ett konto?")}{" "}
                  <button
                    type="button"
                    style={loginButtonStyle}
                    onClick={updateLoginProcess}
                  >
                    {t("login_btn")}
                  </button>
                </p>
              )}

              {/* otherwise show signin form */}
              {isLoginProcess && (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Är du ny här?")}{" "}
                  <button
                    type="button"
                    style={loginButtonStyle}
                    onClick={updateLoginProcess}
                  >
                    {t("create_acc_btn")}
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(GiftCard);
