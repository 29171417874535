import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { useTranslation } from 'react-i18next'
import * as ROUTES from '../../constants/routes'


const CheckoutForm = (props) => {
    const { t, i18n } = useTranslation()
    const baseUrl = i18n.language === 'sv' ? '' : '/' + i18n.language;

    const stripe = useStripe()
    const elements = useElements()

    // const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (!stripe) return

        let clientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");

        if (clientSecret) { // future payment
            stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                let message = ''
                switch (setupIntent.status) {
                    case "succeeded":
                        message = "payment_success"
                        break

                    case "processing":
                        message = "payment_processing"
                        break

                    case "requires_payment_method":
                        message = "Payment failed. Please try another payment method."
                        // Redirect your user back to your payment page to attempt collecting
                        // payment again
                        break

                    default:
                        message = "payment_failed"
                        break
                }

                setMessage(message)

                navigate(baseUrl + ROUTES.ACCOUNT)

            })

        } else {
            clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret")
            if (clientSecret) { // immediate payment
                stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                    let message = ''
                    switch (paymentIntent.status) {
                        case "succeeded":
                            message = "payment_success"
                            break

                        case "processing":
                            message = "payment_processing"
                            break

                        case "requires_payment_method":
                            message = "requires_payment_method"
                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                            break

                        default:
                            message = "payment_failed"
                            break
                    }

                    setMessage(message)

                    navigate(baseUrl + ROUTES.ACCOUNT)

                })
            }
        }
    }, [stripe])

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        if (!stripe || !elements) return

        setIsLoading(true)

        if (props.isFuturePayment) {
            // Confirm the SetupIntent
            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/subscribe`,
                    //https://localhost:3000/subscribe?setup_intent=seti_1MqMXrFha9hdCHBg5fZ3xnfx&setup_intent_client_secret=seti_1MqMXrFha9hdCHBg5fZ3xnfx_secret_NbZhpUt9ASwL1kHhswlNyMBGmfpjM4T&redirect_status=succeeded
                },
            })

            if (error.message) {
                setMessage(error.message)
            } else {
                setMessage("An unexpected error occurred")
            }

        } else {
            const { error } = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/subscribe`,
                    // https://localhost:3000/subscribe?payment_intent=pi_3MBlLtFha9hdCHBg1K6esYYE&payment_intent_client_secret=pi_3MBlLtFha9hdCHBg1K6esYYE_secret_6s87kBLAfoIbJ0w0fAwvworRx&redirect_status=succeeded
                },
            })

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message)
            } else {
                setMessage("An unexpected error occurred.")
            }
        }

        setIsLoading(false)
    }

    const paymentElementOptions = {
        layout: "tabs",
    }

    return (
        <div className='stripeWarp'>
            <form onSubmit={handleSubmit} className="stripeForm" id="payment-form">
                {/* <LinkAuthenticationElement
                    id="link-authentication-element"
                    onChange={(e) => setEmail(e.target.value)}
                /> */}
                <PaymentElement id="payment-element" options={paymentElementOptions} />

                <button disabled={isLoading || !stripe || !elements} className='buttonStyle' type='submit'>
                    <span className='button-text'>
                        {isLoading ? <div className="spinner"></div> : t('Pay now')}
                    </span>
                </button>

                {/* Show any error or success messages */}
                {message && (
                    <p id="payment-message" className='errorMessage'>
                        {t(message)}
                    </p>
                )}
            </form>
        </div>
    )
}


export default CheckoutForm