import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BlancheLogoRed,
  AppstoreIcon,
  GooglePlayIcon,
  RightNavIcon,
  MenuIcon,
} from "../Icons";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";

const NavigationBase = (props) => {
  const { i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  // array to list pages route without menu
  const pathArr = [
    baseUrl + "/",
    // baseUrl + ROUTES.SIGN_IN,
    // baseUrl + ROUTES.SIGN_UP,
    // baseUrl + ROUTES.SUBSCRIBE,
    // baseUrl + ROUTES.THANKYOU,
    // baseUrl + ROUTES.Gift_card_confirm,
    // baseUrl + ROUTES.Gift_card_page,
    // baseUrl + ROUTES.Gift_card_redeem,
  ];

  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    if (token) {
      // Use the token to sign in
      props.firebase
        .signInWithCustomToken(token)
        .then(() => {
          navigate(pathname);
        })
        .catch((error) => {
          console.error("Error signing in with custom token:", error);
        });
    }
  }, [search, pathname]);

  return (
    <AuthUserContext.Consumer>
      {(authUser) => {
        return <NavigationAuth authUser={authUser} props={props} />;
        /* if (!authUser) return <></>;

        return pathArr.includes(pathname) === true ||
          pathArr.includes(pathname + "/") === true ? (
          <></>
        ) : (
          <NavigationAuth authUser={authUser} props={props} />
        ); */
      }}
    </AuthUserContext.Consumer>
  );
};

const NavigationAuth = ({ authUser, props }) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;
  const classes = "siderBarMenu";

  function hideShow() {
    if (window.outerWidth <= 768) {
      let ele = "";

      let ele1 = document.getElementsByClassName("mobilemenuIcon");
      ele1[0].setAttribute("mobile", "true");

      ele = document.getElementsByClassName("menuList");
      ele[0].setAttribute("class", "menuList hide");

      ele = document.getElementsByClassName("appDownloadIcons");
      ele[0].setAttribute("class", "appDownloadIcons hide");

      ele = document.getElementsByClassName("accountBox");
      if (ele.length) ele[0].setAttribute("class", "accountBox");
      else {
        ele = document.getElementsByClassName("landingPage");
        if (ele.length) {
          const cl = ele[0].getAttribute("class");
          ele[0].setAttribute("class", cl.replace(" hide", ""));
        }
      }

      document
        .getElementById("openMenu")
        .setAttribute("class", "mobilemenuIcon");
      document
        .getElementById("closeMenu")
        .setAttribute("class", "mobilemenuIcon hide");
    }
  }

  useEffect(() => {
    const oldLang = window.localStorage.getItem("defaultLang");
    if (oldLang !== i18n.language && authUser?.uid) {
      // console.log('authUser', authUser.uid, i18n.language)
      // update language in localStorage
      window.localStorage.setItem("defaultLang", i18n.language);

      // update language in user's record
      props.firebase.firedb
        .collection("users")
        .doc(authUser.uid)
        .update({ emailLang: i18n.language });
    }
  }, [i18n.language, authUser?.uid, props.firebase.firedb]);

  useEffect(() => {
    let ignore = false;

    function applyOnMobileView() {
      if (window.outerWidth <= 768) {
        let onLoadInterval = setInterval(() => {
          if (document.getElementsByClassName("menuList")[0]) {
            hideShow();

            clearInterval(onLoadInterval);
          }
        }, 100);
      }
    }

    if (!ignore) applyOnMobileView();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className={classes}>
      <header>
        <BlancheLogoRed
          className="logo"
          to={
            window.location.pathname === "/thankyou"
              ? window.location.origin + "/payment"
              : null
          }
        />
        <MenuIcon />
      </header>

      <ul className="menuList">
        {(authUser && (
          <>
            <li onClick={hideShow}>
              <Link to={baseUrl + ROUTES.ACCOUNT}>
                {t("Hantera konto")} <RightNavIcon />
              </Link>
            </li>
            <li onClick={hideShow}>
              <Link to={baseUrl + ROUTES.PAYMENT}>
                {t("Hantera abonnemang")} <RightNavIcon />
              </Link>
            </li>
            <li onClick={hideShow}>
              <Link to={'https://blanchestories.com/presentkort/'}>
                {t("Gift Card")} <RightNavIcon />
              </Link>
            </li>
            {/* <li onClick={hideShow}>
              <Link to={baseUrl + ROUTES.Gift_card_redeem}>
                {t("Gift Card Redeem")} <RightNavIcon />
              </Link>
            </li> */}
            <li>
              <SignOutButton icon={<RightNavIcon />} />
            </li>
          </>
        )) || (
          <>
            <li onClick={hideShow}>
              <Link to={baseUrl + ROUTES.SUBSCRIBE}>
                {t("Subscribe")} <RightNavIcon />
              </Link>
            </li>
            <li onClick={hideShow}>
              <Link to={'https://blanchestories.com/presentkort'}>
                {t("Gift Card")} <RightNavIcon />
              </Link>
            </li>
            {/* <li onClick={hideShow}>
              <Link to={baseUrl + ROUTES.Gift_card_redeem}>
                {t("Gift Card Redeem")} <RightNavIcon />
              </Link>
            </li> */}
            <li onClick={hideShow}>
              <Link to={baseUrl + ROUTES.SIGN_IN}>
                {t("login_btn")} <RightNavIcon />
              </Link>
            </li>
          </>
        )}

        <li>
          <LanguageSwitcher list={true} />
        </li>
      </ul>

      <div className="appDownloadIcons hide">
        <a
          href="https://play.google.com/store/apps/details?id=com.blanchestories.blanchestories&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          className="icon"
        >
          <GooglePlayIcon />
        </a>
        <a
          href="https://apps.apple.com/us/app/blanchestories/id1539883289?itsct=apps_box_badge&itscg=30200"
          className="icon"
        >
          <AppstoreIcon />
        </a>
      </div>
    </div>
  );
};

function LanguageSwitcher(props) {
  const { t, i18n } = useTranslation();
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();

  let newUrl = `${pathname}${hash}${search}`.replace(/\/$/, "");
  useEffect(() => {
    if (pathname.substring(1, 3) !== "en" && i18n.language === "en") {
      navigate("/en" + newUrl);
    }

    // set language attribute on HTML element
    document.documentElement.setAttribute("lang", i18n.language);
  }, [pathname, i18n.language]);

  let langSwitching = false;
  var updateLang = (lang) => {
    // return if current language is same
    if (i18n.language === lang) return;

    // return if language is being switch
    if (langSwitching) return;
    langSwitching = true;

    i18n.changeLanguage(lang, (err, t) => {
      if (err)
        return console.log("something went wrong changeLanguage", lang, err);

      if (lang === "en") {
        newUrl = `/en${newUrl}`;
        // newUrl = window.location.pathname.replace('/', '/en/');
      } else {
        newUrl = newUrl.substring(3);
        newUrl = newUrl.length === 0 ? "/" : newUrl;
        // newUrl = window.location.pathname.replace('/en', '');
      }

      // window.location.replace(newUrl)
      navigate(newUrl);
    });
  };

  let html = (
    <div className="dropdownLang">
      <button>{t("lang_btn")}</button>
      <ul>
        <li className={i18n.language === "en" ? "active" : ""}>
          <button onClick={() => updateLang("en")}>{t("English")}</button>
        </li>
        <li className={i18n.language === "sv" ? "active" : ""}>
          <button onClick={() => updateLang("sv")}>{t("Swedish")}</button>
        </li>
      </ul>
    </div>
  );

  if (props.list) {
    html = (
      <div className="switchLang">
        <span
          className={i18n.language === "en" ? "active" : ""}
          onClick={() => updateLang("en")}
        >
          EN
        </span>
        <span
          className={i18n.language === "sv" ? "active" : ""}
          onClick={() => updateLang("sv")}
        >
          SV
        </span>
      </div>
    );
  }

  return html;
}

const Navigation = withFirebase(NavigationBase);

export default Navigation;

export { LanguageSwitcher };
