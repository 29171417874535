import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { AuthUserContext } from '../Session'
import * as ROUTES from '../../constants/routes'

import { useTranslation } from 'react-i18next'

import { BlancheLogoRed } from '../Icons'
import { LanguageSwitcher } from '../Navigation'

const PageNotFound = () => {
  const { t, i18n } = useTranslation()
  const baseUrl = i18n.language === 'sv' ? '' : '/' + i18n.language;

  const navigate = useNavigate()

  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          navigate(baseUrl + ROUTES.ACCOUNT)
        ) : (
          <div className='landingPage signin-page'>
            <div className='centerContent logoSection'>
              <div style={{ width: '100%' }}>
                <BlancheLogoRed className="logo" />

                <LanguageSwitcher list={true} />
              </div>
            </div>
            <div className='centerContent'>
              <div className='sectionContent'>
                <h1>{t('404_page_heading')}</h1>
                <h1>{t('404_page_sub_heading')}</h1>
                <p>{t('404_page_desc')}</p>
                <Link to={baseUrl + '/'}>{t('Home')}</Link>
              </div>
            </div>
          </div>
        )
      }
    </AuthUserContext.Consumer>
  )
}

export default PageNotFound;