import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import * as serviceWorker from './serviceWorker'

import App from './components/App'
import Firebase, { FirebaseContext } from './components/Firebase'

import TagManager from 'react-gtm-module'

import "./i18n.js"


TagManager.initialize({
  gtmId: 'GTM-TS5SCBR'
})

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Suspense fallback="loading">
      <App />
    </Suspense>
  </FirebaseContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
