import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { updateUserDataInDB } from "../SignIn";
import { PasswordForgetLink } from "../PasswordForget";
import { BlancheLogoRed, GoogleIcon } from "../Icons";
import * as ROUTES from "../../constants/routes";

import { Trans, useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../Navigation";

import {
  linkStyle,
  buttonLinkStyle,
  loginButtonStyle,
  ErrorsObj,
} from "../common";

import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const GiftCardRedeem = (props) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    uid: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false); // user is currently login ?
  const [isLoginProcess, setIsLoginProcess] = useState(false); // user is going to login or signup ?
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [giftCode, setGiftCode] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    let isMounted = true;

    if (props.firebase.auth.currentUser && isMounted) {
      userInfo.uid = props.firebase.auth.currentUser.uid;
      userInfo.email = props.firebase.auth.currentUser.email;

      setIsLoggedIn(true);
    }

    return () => {
      isMounted = false;
    };
  }, [props.firebase.auth.currentUser, userInfo]);

  const verifyLoadCheckout = async () => {
    if (!userInfo.uid) return;

    setOpenModal(false);

    setIsLoading(true);

    try {
      const config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SERVER_API_URL}/stripe/redeemGIFTCard`,
        headers: {
          "X-API-KEY": `${process.env.REACT_APP_SERVER_API_KEY}`,
        },
        data: {
          userId: userInfo.uid,
          giftCardSpecialNumber: giftCode,
        },
      };
      const response = await axios.request(config);

      if (response.data.success) {
        window.sessionStorage.setItem(
          "giftCardDetails",
          JSON.stringify(response.data)
        );

        navigate(ROUTES.Gift_card_confirm);
      } else {
        setError({ message: "Request failed" });
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setError({ message: error.response.data.message });
      } else {
        setError({ message: error.message });
      }
    }

    setIsLoading(false); // hide loader
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((userInfo) => ({ ...userInfo, [name]: value }));
  };

  const updateLoginProcess = (event) => {
    event.preventDefault();
    setIsLoginProcess(!isLoginProcess);
  };

  const catchError = (error) => {
    console.log("error", error);
    if (ErrorsObj.hasOwnProperty(error.code)) {
      error.message = ErrorsObj[error.code];
    }

    setError(error);
  };

  const submitUser = (event) => {
    event.preventDefault();
    setIsLoading(true); // show loader

    var { email, password } = userInfo;

    if (isLoginProcess) {
      props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(async (authUser) => {
          userInfo.uid = authUser.user.uid;

          // Create a user in your Firebase Cloud Database too
          await updateUserDataInDB(props, userInfo.uid, {
            emailLang: i18n.language === "sv" ? "sw" : "en",
          });

          await verifyLoadCheckout();
        })
        .catch((error) => {
          setIsLoading(false); // hide loader
          catchError(error);
        });
    } else {
      props.firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(async (authUser) => {
          // Create a user in your Firebase realtime database
          // console.log('CREATED', authUser, props.route)
          userInfo.uid = authUser.user.uid;

          await updateUserDataInDB(props, userInfo.uid, {
            uid: userInfo.uid,
            email: email,
            displayname: userInfo.displayname,
            emailLang: i18n.language === "sv" ? "sw" : "en",
          });

          await verifyLoadCheckout();

          // setUserInfo(INITIAL_STATE)
          setError(null);
        })
        .catch((error) => {
          setIsLoading(false); // hide loader
          catchError(error);
        });
    }
  };

  const googleLogin = (event) => {
    setIsLoading(true); // show loader
    props.firebase
      .doSignInWithGoogle()
      .then(async (socialAuthUser) => {
        // console.log('socialAuthUser', socialAuthUser)
        userInfo.uid = socialAuthUser.user.uid;
        userInfo.email = socialAuthUser.user.email;

        // Create a user in your Firebase Cloud Database too
        await updateUserDataInDB(props, userInfo.uid, {
          uid: userInfo.uid,
          displayname: userInfo.displayname || socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          emailLang: i18n.language === "sv" ? "sw" : "en",
        });

        await verifyLoadCheckout();

        setError(null);
      })
      .catch((error) => {
        catchError(error);
        setIsLoading(false); // hide loader
      });

    event.preventDefault();
  };

  const buyPlan = async () => {
    // alert(giftCode);
    // return;
    if (!giftCode) {
      setError({ message: "Please fill giftcard code" });
      return;
    } else {
      if (giftCode.length < 16) {
        setError({ message: "Gift card code is invalid" });
        return;
      }
      setError(null);
    }

    if (!isLoggedIn) {
      setOpenModal(true);
      return;
    }
    verifyLoadCheckout();
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="landingPage newTemplate">
          {!authUser && (
            <div className="centerContent logoSection">
              <div style={{ width: "100%" }}>
                <BlancheLogoRed className="logo" />

                <LanguageSwitcher list={true} />
              </div>
            </div>
          )}

          <div className="centerContent">
            <div
              className="sectionContent"
              style={{ width: "500px", maxWidth: "90%" }}
            >
              <div style={{ width: "100%" }}>
                <h2
                  className=""
                  style={{
                    fontSize: "25px",
                    fontWeight: "normal",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {t("Redeem a gift card")}
                </h2>

                <p
                  className=""
                  style={{
                    fontSize: "23px",
                    textAlign: "left",
                    width: "100%",
                    marginBottom: "5px",
                  }}
                >
                  {t("Gift card (16 characters)")}
                </p>

                <input
                  type="text"
                  name="giftCode"
                  className="redeemInput"
                  value={giftCode}
                  onChange={(event) => {
                    const { value } = event.target;
                    let v1 = value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (v1.length > 16) {
                      v1 = v1.substring(0, 16); // Limit to 16 digits
                    }
                    v1 = v1.match(/.{1,4}/g)?.join("-") || ""; // Add hyphens
                    event.target.value = v1;
                    setGiftCode(v1);
                  }}
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                  maxLength={19}
                  style={{
                    background: "#fff",
                    fontSize: "23px",
                    fontWeight: 400,
                    textAlign: "left",
                    width: "100%",
                    border: "1px solid #9f1c44",
                    padding: "7px 20px",
                    minHeight: "59px",
                  }}
                ></input>

                {error && (
                  <>
                    <p></p>
                    <p
                      className="errorMessage"
                      style={{
                        fontSize: "18px",
                        background: "transparent",
                        padding: 0,
                        color: (error.success && "green") || "#9f1c44",
                      }}
                    >
                      {error.message}
                    </p>
                  </>
                )}
                <p></p>

                <button
                  type="button"
                  style={{ ...buttonLinkStyle, width: "100%" }}
                  onClick={() => buyPlan()}
                  disabled={isLoading}
                >
                  <span className="button-text">
                    {(isLoading && <div className="spinner"></div>) ||
                      t("Activate gift card")}
                  </span>
                </button>

                <p>&nbsp;</p>
                <h2
                  className="planName"
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {t("You want to purchase gift cards?")}
                </h2>

                <p></p>
                <button
                  type="button"
                  style={{ ...buttonLinkStyle }}
                  onClick={() => {
                    navigate(baseUrl + ROUTES.Gift_card_page);
                  }}
                >
                  {t("View the gift cards")}
                </button>
              </div>
            </div>
          </div>

          <div className={`popupSection ${openModal ? "" : "hide"}`}>
            <div
              className="popupOverlay"
              onClick={() => setOpenModal(false)}
            ></div>

            <div className="popupContent">
              <form method="POST" action="" onSubmit={submitUser}>
                <h2 className="sectionTitle">
                  {isLoginProcess
                    ? t("Välkommen tillbaka")
                    : t("Kom igång med ett konto")}
                </h2>

                {!isLoginProcess && (
                  <input
                    name="displayname"
                    value={userInfo.displayname}
                    onChange={onChange}
                    type="text"
                    placeholder={t("name")}
                    className="inputStyle"
                    required
                  />
                )}

                <input
                  name="email"
                  value={userInfo.email}
                  onChange={onChange}
                  type="email"
                  placeholder={t("email_lbl")}
                  className="inputStyle"
                  required
                />

                <label style={{ display: 'block', position: 'relative' }}>
                  <input
                    name="password"
                    value={userInfo.password}
                    onChange={onChange}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("passw_lbl")}
                    className="inputStyle"
                  />
                  {!showPassword && <span onClick={() => setShowPassword(true)}><Visibility style={{ position: 'absolute', right: '10px', top: '13.5px' }}></Visibility></span>}
                  {showPassword && <span onClick={() => setShowPassword(false)}><VisibilityOff style={{ position: 'absolute', right: '10px', top: '13.5px' }}></VisibilityOff></span>}
                </label>

                {error && (
                  <p className="errorMessage">
                    {error.message === "email-already-in-use" ? (
                      <Trans i18nKey="email-already-in-use">
                        Mejladressen finns redan registrerad. Du kan{" "}
                        <a href={baseUrl + "/"} style={linkStyle}>
                          logga in
                        </a>{" "}
                        eller{" "}
                        <a
                          href={
                            baseUrl +
                            ROUTES.PASSWORD_FORGET +
                            `${userInfo.email ? "?email=" + userInfo.email : ""
                            }`
                          }
                          style={linkStyle}
                        >
                          återställa lösenordet
                        </a>
                        .
                      </Trans>
                    ) : (
                      <>{t(error.message)}</>
                    )}
                  </p>
                )}

                {error && <PasswordForgetLink email={userInfo.email} />}

                <p style={{ fontSize: "18px", wordBreak: "break-word" }}>
                  Genom att fortsätta godkänner du våra{" "}
                  <a
                    href="https://blanchestories.com/terms-of-use/"
                    style={linkStyle}
                  >
                    Användarvillkor
                  </a>{" "}
                  och vår{" "}
                  <a
                    href="https://blanchestories.com/privacy-policy/"
                    style={linkStyle}
                  >
                    Sekretesspolicy
                  </a>
                  .
                </p>

                <button type="submit" className="buttonStyle socialButton">
                  <span className="button-text">
                    {isLoading ? (
                      <div className="spinner"></div>
                    ) : isLoginProcess ? (
                      t("login_btn")
                    ) : (
                      t("create_acc_btn")
                    )}
                  </span>
                </button>

                <button
                  type="submit"
                  className="buttonStyle socialButton"
                  onClick={(e) => {
                    if (isLoginProcess || userInfo.displayname) {
                      e.preventDefault();
                      googleLogin();
                      return false;
                    }
                  }}
                >
                  <GoogleIcon style={{ marginRight: "8px" }} />
                  {t(isLoginProcess ? "login_with" : "acc_create_with", {
                    lw: "Google",
                  })}
                </button>
              </form>

              {/* when user not logged, show signup form */}
              {!isLoginProcess && (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Har du redan ett konto?")}{" "}
                  <button
                    type="button"
                    style={loginButtonStyle}
                    onClick={updateLoginProcess}
                  >
                    {t("login_btn")}
                  </button>
                </p>
              )}

              {/* otherwise show signin form */}
              {isLoginProcess && (
                <p
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Är du ny här?")}{" "}
                  <button
                    type="button"
                    style={loginButtonStyle}
                    onClick={updateLoginProcess}
                  >
                    {t("create_acc_btn")}
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(GiftCardRedeem);
