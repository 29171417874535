import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { withFirebase } from '../Firebase'
// eslint-disable-next-line no-unused-vars
import { AuthUserContext } from '../Session'

import * as ROUTES from '../../constants/routes'

import { useTranslation } from 'react-i18next'

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: ''
}

const ChangePasswordPage = props => {
  const { t, i18n } = useTranslation()
  const baseUrl = i18n.language === 'sv' ? '' : '/' + i18n.language;

  const [passwords, setPasswords] = useState(INITIAL_STATE)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const onSubmit = event => {
    const { passwordOne } = passwords
    // console.log(passwordOne);
    // navigate(baseUrl + ROUTES.ACCOUNT);
    props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        setPasswords(INITIAL_STATE)
        navigate(baseUrl + ROUTES.ACCOUNT)
        setError(null)
      })
      .catch(error => {
        setError(error)
      })

    event.preventDefault()
  }

  const onChange = event => {
    const { name, value } = event.target
    // console.log(name, value);
    setPasswords({ ...passwords, [name]: value })
  }

  const { passwordOne, passwordTwo } = passwords

  const isInvalid = passwordOne !== passwordTwo || passwordOne === ''

  return (
    <div className='accountBox'>
      <div className='accountContent'>
        <h1 className='sectionTitle'>{t('change_passw_page_heading')}</h1>
        <p>{t('change_passw_page_desc')}</p>
        <form onSubmit={onSubmit}>
          <input
            name='passwordOne'
            value={passwordOne}
            onChange={onChange}
            type='password'
            placeholder={t('new_passw_text')}
            className='inputStyle'
            style={{ marginLeft: 0 }}
            autoComplete='new-password'
          />
          <input
            name='passwordTwo'
            value={passwordTwo}
            onChange={onChange}
            type='password'
            placeholder={t('passw_confirm_text')}
            className='inputStyle'
            style={{ marginLeft: 0 }}
            autoComplete='new-password'
          />
          <button disabled={isInvalid} type='submit' className='buttonStyle'>{t('save_btn')}</button>

          {error && (
            <p className='errorMessage' style={{ marginLeft: 0 }}>
              {t(error.message)}
            </p>
          )}
        </form>
      </div>
    </div>
  )
}

export default withFirebase(ChangePasswordPage)
