import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { compose } from "recompose";

import { AuthUserContext } from "../Session";

import { SignUpLink } from "../SignUp";
import { FacebookIcon, GoogleIcon, BlancheLogoRed } from "../Icons";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import { LanguageSwitcher } from "../Navigation";
import { ErrorsObj, linkStyle } from "../common";

import { Trans, useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const policyTextStyle = {
  width: "100%",
  maxWidth: "273px",
  marginBottom: "16px",
  fontSize: "14px",
  display: "block",
  margin: "auto",
  paddingBottom: "40px",
  letterSpacing: ".2px",
};

const SignInPage = (props) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [redirectTo, setRedirectTo] = useState(baseUrl + ROUTES.ACCOUNT);

  useEffect(() => {
    let isMounted = true;
    if (props.firebase.auth.currentUser && isMounted) {
      navigate(redirectTo);
    }

    if (searchParams.get("redirectTo") && isMounted) {
      setRedirectTo(searchParams.get("redirectTo"));
    }

    return () => {
      isMounted = false;
    };
  }, [
    props.firebase.auth.currentUser,
    baseUrl,
    navigate,
    redirectTo,
    searchParams,
  ]);

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        !authUser && (
          <div className="landingPage signin-page">
            <div className="centerContent logoSection">
              <div style={{ width: "100%" }}>
                <BlancheLogoRed className="logo" />

                <LanguageSwitcher list={true} />
              </div>
            </div>

            <div className="centerContent">
              <div className="sectionContent pkfullw">
                <h1 className="sectionTitle">{t("Välkommen tillbaka")}</h1>
                <p>{t("Kom och låt våra stories förföra dig")}</p>
                <SignInForm route={redirectTo} />
                {/* <SignInFacebook
                  route={redirectTo}
                  title={t('login_with', { lw: 'Facebook' })}
                /> */}
                <SignInGoogle
                  route={redirectTo}
                  title={t("login_with", { lw: "Google" })}
                />
                {/* <SignInTwitter /> */}
                <SignUpLink />
                <p style={policyTextStyle}>
                  <Trans i18nKey="terms_privacy_text">
                    Genom att fortsätta godkänner du våra
                    <a
                      href="https://blanchestories.com/terms-of-use/"
                      style={linkStyle}
                    >
                      Användarvillkor
                    </a>
                    och vår
                    <a
                      href="https://blanchestories.com/privacy-policy/"
                      style={linkStyle}
                    >

                      Sekretesspolicy
                    </a>
                    .
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        )
      }
    </AuthUserContext.Consumer>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
};

const updateUserDataInDB = async (props, uid, data) => {
  await props.firebase.firedb
    .collection("users")
    .doc(uid)
    .get()
    .then(async (res) => {
      // console.log('updateUserDataInDB', res, res.data(), res.exists)
      if (res.exists) {
        console.log("updateUserDataInDB- user exist");
        await props.firebase.firedb.collection("users").doc(uid).update(data);
      } else {
        console.log("updateUserDataInDB- user new");
        await props.firebase.firedb
          .collection("users")
          .doc(uid)
          .set({ ...data, createdIn: new Date() });
      }
    });
};

const SignInFormBase = (props) => {
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);

  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate();

  const onSubmit = (event) => {
    const { email, password } = userInfo;

    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setUserInfo(INITIAL_STATE);
        navigate(props.route);
      })
      .catch((error) => {
        console.log("error");
        if (ErrorsObj.hasOwnProperty(error.code)) {
          error.message = ErrorsObj[error.code];
        }
        setError(error);
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((userInfo) => ({ ...userInfo, [name]: value }));
  };

  const isInvalid = userInfo.password === "" || userInfo.email === "";
  return (
    <form onSubmit={onSubmit} className="app-form signin-form">
      <input
        name="email"
        value={userInfo.email}
        onChange={onChange}
        type="text"
        placeholder={t("email_lbl")}
        className="inputStyle"
      />

      <label style={{ display: 'block', position: 'relative' }}>
        <input
          name="password"
          value={userInfo.password}
          onChange={onChange}
          type={showPassword ? "text" : "password"}
          placeholder={t("passw_lbl")}
          className="inputStyle"
        />
        {!showPassword && <span onClick={() => setShowPassword(true)}><Visibility style={{ position: 'absolute', right: '10px', top: '13.5px' }}></Visibility></span>}
        {showPassword && <span onClick={() => setShowPassword(false)}><VisibilityOff style={{ position: 'absolute', right: '10px', top: '13.5px' }}></VisibilityOff></span>}
      </label>

      <input type="submit" style={{ display: "none" }} />

      {error && <p className="errorMessage">{t(error.message)}</p>}
      {error && <PasswordForgetLink email={userInfo.email} />}

      <input type="submit" style={{ display: "none" }} />

      <button disabled={isInvalid} type="submit" className="buttonStyle">
        {t("login_btn")}
      </button>
    </form>
  );
};

const SignInFacebookBase = (props) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onSubmit = (event) => {
    props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Cloud Database too
        updateUserDataInDB(props, socialAuthUser.user.uid, {
          uid: socialAuthUser.user.uid,
          email: socialAuthUser.user.email,
        });

        setError(null);
        //Check if subscription
        navigate(props.route);
      })
      .catch((error) => {
        if (ErrorsObj.hasOwnProperty(error.code)) {
          error.message = ErrorsObj[error.code];
        }
        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit} className="app-form signin-form">
      <button type="submit" className="buttonStyle socialButton">
        <FacebookIcon style={{ marginRight: "8px" }} />
        {props.title}
      </button>
      {error && <p className="errorMessage">{t(error.message)}</p>}
    </form>
  );
};

const SignInGoogleBase = (props) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onSubmit = (event) => {
    props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // console.log('WHAT1');

        // Create a user in your Firebase Cloud Database too
        updateUserDataInDB(props, socialAuthUser.user.uid, {
          uid: socialAuthUser.user.uid,
          email: socialAuthUser.user.email,
        });

        setError(null);
        navigate(props.route);
      })
      .catch((error) => {
        if (ErrorsObj.hasOwnProperty(error.code)) {
          error.message = ErrorsObj[error.code];
        }
        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit} className="app-form signin-form">
      <button type="submit" className="buttonStyle socialButton">
        <GoogleIcon style={{ marginRight: "8px" }} />
        {props.title}
      </button>

      {error && <p className="errorMessage">{t(error.message)}</p>}
    </form>
  );
};

const withRouter = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInFacebook = compose(withRouter, withFirebase)(SignInFacebookBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

export default withFirebase(SignInPage);

export { SignInForm, SignInGoogle, SignInFacebook, updateUserDataInDB };
