import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withAuthorization, AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'
import { useNavigate } from 'react-router-dom'
import { FacebookIcon, GoogleIcon } from '../Icons'
// import { PasswordForgetLink } from '../PasswordForget';
import { SIGN_IN_METHODS } from '../../constants/signInMethods'
import * as ROUTES from '../../constants/routes'

import { useTranslation } from 'react-i18next'

const AccountBase = ({ firebase }) => {
  const { t, i18n } = useTranslation()
  const baseUrl = i18n.language === 'sv' ? '' : '/' + i18n.language;

  const navigate = useNavigate()

  const goToPasswordPage = () => {
    navigate(baseUrl + ROUTES.PASSWORD_CHANGE)
  }

  const goToEmailPage = () => {
    navigate(baseUrl + ROUTES.EMAIL_CHANGE)
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className='accountBox'>
          <div className='accountContent'>
            <h1 className='sectionTitle'>{t('Hantera konto')}</h1>
            <p>
              <span style={{ color: '#9e0045' }}>{t('Du är inloggad som')}</span>
              <br></br>
              {authUser.email}
            </p>
            {/* <ManageAccountPage authUser={authUser}/> */}
            {/* <PasswordForgetLink /> */}
            <div style={{ display: 'flex', columns: 'auto 2', columnGap: '15px' }}>
              <button
                type='submit'
                className='buttonStyle'
                onClick={goToPasswordPage}
              >
                {t('change_passw_btn')}
              </button>
              <button
                type='submit'
                className='buttonStyle'
                onClick={goToEmailPage}
              >
                {t('change_email_btn')}
              </button>
            </div>

            <p></p>
            <LoginManagement authUser={authUser} />
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const LoginManagementBase = props => {
  const { t } = useTranslation()

  const [error, setError] = useState(null)
  const [activeSignInMethods, setActiveSignInMethods] = useState([])
  // console.log(props.authUser.uid, props.authUser.email);

  useEffect(() => fetchSignInMethods(), [])

  const fetchSignInMethods = () => {
    props.firebase.auth
      .fetchSignInMethodsForEmail(props.authUser.email)
      .then(activeSignInMethods => {
        setError(null)
        setActiveSignInMethods(activeSignInMethods)
      })
      .catch(error => setError(error))
  }

  const onSocialLoginLink = provider => {
    props.firebase.auth.currentUser
      .linkWithPopup(props.firebase[provider])
      .then(fetchSignInMethods)
      .catch(error => setError(error))
  }

  // const onDefaultLoginLink = password => {
  //   const credential = props.firebase.emailAuthProvider.credential(
  //     props.authUser.email,
  //     password,
  //   );

  //   props.firebase.auth.currentUser
  //     .linkAndRetrieveDataWithCredential(credential)
  //     .then(fetchSignInMethods)
  //     .catch(error => setError(error));
  // };

  const onUnlink = providerId => {
    props.firebase.auth.currentUser
      .unlink(providerId)
      .then(fetchSignInMethods)
      .catch(error => setError(error))
  }

  return (
    <div>
      {t('Fler sätt att logga in')}
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {SIGN_IN_METHODS.map(signInMethod => {
          const onlyOneLeft = activeSignInMethods.length === 1
          const isEnabled = activeSignInMethods.includes(signInMethod.id)

          return (
            <li key={signInMethod.id}>
              {signInMethod.id === 'password' ? (
                <></>
              ) : (
                // <DefaultLoginToggle
                //   onlyOneLeft={onlyOneLeft}
                //   isEnabled={isEnabled}
                //   signInMethod={signInMethod}
                //   onLink={onDefaultLoginLink}
                //   onUnlink={onUnlink}
                // />
                <SocialLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onSocialLoginLink}
                  onUnlink={onUnlink}
                  t={t}
                />
              )}
            </li>
          )
        })}
      </ul>
      {error && (
        <p className='errorMessage'>
          {t(error.message)}
        </p>
      )}
    </div>
  )
}

const SocialIcon = props => {
  if (props.name === 'Facebook') {
    return <FacebookIcon style={{ marginRight: '8px' }} />
  } else if (props.name === 'Google') {
    return <GoogleIcon style={{ marginRight: '8px' }} />
  } else {
    return <></>
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
  t
}) =>
  isEnabled ? (
    <button
      type='button'
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
      className='buttonStyle socialButton'
    >
      <SocialIcon name={signInMethod.name} />
      {t('Avaktivera')} {signInMethod.name}
    </button>
  ) : (
    <button
      type='button'
      onClick={() => onLink(signInMethod.provider)}
      className='buttonStyle socialButton'
    >
      <SocialIcon name={signInMethod.name} />
      {t('Länka')} {signInMethod.name}
    </button>
  )

// const INITIAL_STATE = {
//   password: '',
//   confirmPassword: '',
// };

// const DefaultLoginToggle = (props) => {
//   const [passwords, setPasswords] = useState(INITIAL_STATE);

//   const onSubmit = (event) => {
//     event.preventDefault();

//     const { passwordOne } = passwords;
//     props.onLink(passwordOne);
//     setPasswords(INITIAL_STATE);
//   };

//   const onChange = (event) => {
//     const { name, value } = event;
//     setPasswords({ ...passwords, [name]: value });
//   };

//   const {
//     onlyOneLeft,
//     isEnabled,
//     signInMethod,
//     onUnlink,
//   } = props;

//   const { passwordOne, passwordTwo } = passwords;

//   const isInvalid =
//     passwordOne !== passwordTwo || passwordOne === '';

//   return isEnabled ? (
//     <button
//       type='button'
//       onClick={() => onUnlink(signInMethod.id)}
//       disabled={onlyOneLeft}
//     >
//       Avaktivera {signInMethod.name}
//     </button>
//   ) : (
//     <form onSubmit={onSubmit}>
//       <input
//         name='passwordOne'
//         value={passwordOne}
//         onChange={onChange}
//         type='password'
//         placeholder='New Password'
//         className='inputStyle'
//       />
//       <input
//         name='passwordTwo'
//         value={passwordTwo}
//         onChange={onChange}
//         type='password'
//         placeholder='Confirm New Password'
//         className='inputStyle'
//       />

//       <button disabled={isInvalid} type='submit' className='buttonStyle'>
//         Länka {signInMethod.name}
//       </button>
//     </form>
//   );
// }

const LoginManagement = withFirebase(LoginManagementBase)
// const ManageAccountPage = withFirebase(ManageAccountBase);
const AccountPage = withFirebase(AccountBase)

const condition = authUser => !!authUser

export default compose(
  // withEmailVerification,
  withAuthorization(condition)
)(AccountPage)
