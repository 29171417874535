export const THANKYOU = "/thankyou";
export const SUBSCRIBE = "/subscribe";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const PAYMENT = "/payment";
export const ACCOUNT = "/account";
export const ACCOUNT_PAYMENT = "/account-payment";
export const PASSWORD_CHANGE = "/change-password";
export const EMAIL_CHANGE = "/change-email";
export const Gift_card_page = "/gift-card-page";
export const Gift_card_redeem = "/gift-card-redeem";
export const Gift_card_confirm = "/gift-card-confirmation";
export const PASSWORD_FORGET = "/password-forget";
export const NOTFOUND = "/404";

export const Yearly100 = "/subscribe/yearly-100"; // year plan - yearly-100
export const Quarter = "/subscribe/quarter"; // 3 months plan - quarter
export const Monthly = "/subscribe/monthly"; // 1 month plan - monthly
export const MonthlyOffer1 = "/subscribe/monthly-offer1"; // 3 month free - monthly

