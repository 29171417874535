import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../Session'
import { withFirebase } from '../Firebase'
import { loadStripe } from '@stripe/stripe-js'

import { useTranslation } from 'react-i18next'

import { taxRates, ErrorsObj, STRIPE_API_KEY, plans } from '../common'
import { useNavigate } from 'react-router-dom'

const PayBase = ({ firebase, switchPlan }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  const currentUser = firebase.auth.currentUser

  const [products, setProducts] = useState([])
  const [subscription, setSubscription] = useState(null)
  const [paymentObj, setPaymentObj] = useState({
    priceId: '',
    coupon: null,
    amount: -1,
    discountAmount: 0,
    total: -1,
    tax: 0
  })
  const [promoObj, setPromoObj] = useState({ promoCode: '' })
  const [isApplyingPromo, setIsApplyingPromo] = useState(false)
  const [isPlanSwitching, setIsPlanSwitching] = useState(false)
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [newPlanId, setNewPlanId] = useState(null)
  const [error, setError] = useState({})
  const [isDiscountCalculated, setIsDiscountCalculated] = useState(false)
  const [checkoutSession, setCheckoutSession] = useState(null)

  const catchError = error => {
    console.log('error', error)
    if (ErrorsObj.hasOwnProperty(error.code)) {
      error.message = ErrorsObj[error.code]
    }

    setError(error)
  }

  /**
   *
   */
  useEffect(() => {
    const getUserSubscription = async ({ subscription }) => {
      if (subscription) setSubscription(subscription)
    }

    getUserSubscription({ subscription: firebase.userSubscription })

  }, [firebase.userSubscription])

  /**
   *
   */
  useEffect(() => {
    const fetchProducts = async () => {
      if (products.length > 0) return;

      const products_arr = await firebase.getProducts();

      setProducts(products_arr);
    }

    fetchProducts();

  }, [firebase.productsFlag])

  /**
   * Create checkout session -
   * Case:-
   * - - If user buying new plan then redirect to Stripe Payment page
   * - - If user Switching plan then only verify request with promocode
   * @param {*} paramObj
   * @param {*} priceId
   *
   * @returns
   */
  const loadCheckout = async ({ promoObj = null, priceId = null, to = null }) => {
    if (switchPlan) {
      // current plan switching
      console.log('loadCheckout called and switchplan true')
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      return await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/createCheckoutSession", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          price: priceId ?? newPlanId ?? null,
          success_url: window.location.origin + '/payment',
          cancel_url: window.location.origin + '/payment',
          promotion_code: promoObj?.id,
          customer_email: currentUser.email
        }),
      })
        .then((res) => {
          setIsApplyingPromo(false)
          if (res.ok === false) {
            return Promise.reject(res)
          }
          return res.json()
        })
        .then(async (res) => {
          // data.id = checkout session id
          // data.url = checkout session url, where user can pay directly
          // data.amount_total = checkout session amount which one needs to pay via user
          console.log('session obj', res)

          if (res.id) {
            setCheckoutSession({ id: res.id, amount: res.amount_total })

            if (promoObj) {
              setPromoObj(promoObj)
              setIsDiscountCalculated(false) // set to false to re-calculate discount
            }
          }
        })
        .catch(error => {
          if (error.message) {
            catchError(error)
          } else {
            error.json().then((error) => {
              if (error.message === 'This coupon cannot be redeemed because it does not apply to anything in this order.') {
                error.code = 'stripe_cannot_reedemed'

                setPromoObj({ promoCode: promoObj.promoCode })
                setIsDiscountCalculated(false) // set to false to re-calculate discount
              }
              catchError(error)
            })
          }
        })
    } else {
      setLoadingCheckout(true)

      // Create CheckOutSession
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("X-API-KEY", process.env.REACT_APP_SERVER_API_KEY);
      await fetch(
        process.env.REACT_APP_SERVER_API_URL + "/stripe/createCheckoutSession",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            email: currentUser.email,
            price: priceId ?? newPlanId ?? null,
            taxRate: taxRates,
            allowPromotionCodes: true,
            successUrl: window.location.origin + "/payment",
            cancelUrl: window.location.origin + "/payment",
            mode: "subscription",
            promoCode: promoObj?.id,
          }),
        }
      )
        .then((res) => {
          if (res.ok === false) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .then(async (session) => {
          console.log("session", session);

          if (session.id) {
            // Make sure to call `loadStripe` outside of a component’s render to avoid
            // recreating the `Stripe` object on every render.
            const stripePromise = await loadStripe(
              STRIPE_API_KEY
            );
            stripePromise.redirectToCheckout({ sessionId: session.id });
          }

          // setLoadingCheckout(false); // hide loader
        })
        .catch((error) => {
          setLoadingCheckout(false); // hide loader
          if (error.message) {
            catchError(error);
          } else {
            error.json().then((error) => {
              if (error.code === "resource_missing")
                error.code = "stripe_resource_missing";
              catchError(error);
            });
          }
        });
    }
  }

  /**
   * update (upgrade/downgrade) current plan
   * @returns
   */
  const switchSubsPlan = async () => {
    if (!newPlanId) {
      catchError({ message: 'Please select a plan' })
      return
    }

    catchError({ message: '' })
    setIsPlanSwitching(true)
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/updateSubscription", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        switchPlan: true,
        priceId: newPlanId,
        subscriptionId: subscription.subscriptionId,
        promotion_code: promoObj.id ?? null,
        taxRates: taxRates,
        checkoutSession
      }),
    })
      .then((res) => {
        setIsPlanSwitching(false)

        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(async (res) => {
        console.log('subs_plan_switched_msg', res)

        setError({
          ...error,
          message: 'subs_plan_switched_msg',
          success: true
        })

        setTimeout(() => {
          window.location.reload()
        }, 5000)
      })
      .catch(error => {
        if (error.message) {
          catchError(error)
        } else {
          error.json().then((error) => {
            catchError(error)
          })
        }
      })
  }

  const removePromoCode = () => {
    setPromoObj({
      promoCode: ''
    })
    setPaymentObj(paymentObj => ({
      ...paymentObj,
      coupon: null,
      discountAmount: -1
    }))
  }

  /**
   * This function only verify and apply given Promo (or Coupon) code
   * before Upgrade/Downgrade current plan
   *
   * @param {*} e
   * @returns
   */
  async function verifyPromoCode(e) {
    e.preventDefault()

    if (!newPlanId) {
      catchError({ message: 'Please select a plan' })
      return
    }

    setIsApplyingPromo(true)
    setError({ message: '' })

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    // headers.append('Accept', 'application/json')

    // Create PaymentIntent as soon as the page loads
    await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/verifyPromoCode", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ promoCode: promoObj.promoCode, }),
    })
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(async res => {
        res.data.promoCode = promoObj.promoCode
        console.log('verifyPromoCode response')

        // Promo code is valid, now check Promo code is valid for selected plan
        await loadCheckout({ promoObj: res.data })
      })
      .catch(error => {
        setIsApplyingPromo(false)

        if (error.message) {
          catchError(error)
        } else {
          error.json().then((error) => {
            catchError(error)
          })
        }
      })
  }

  const updateProduct = async (index) => {
    if (loadingCheckout) return;
    catchError({ message: '' })

    const product = products[index][1]

    /* if (!switchPlan) {
      let to = '/subscribe'
      if (product.prices.id === plans.month) {
        to += '/monthly'
      } else if (product.prices.id === plans.year) {
        to += '/yearly-100'
      }

      navigate(baseUrl + to)
      return
    } */

    const amount = parseFloat(product.prices.data.unit_amount / 100).toFixed(2)
    const tax = parseFloat(amount * 25 / 100).toFixed(2)

    setNewPlanId(product.prices.id)
    setPaymentObj({
      ...paymentObj,
      product: product,
      priceId: product.prices.id,
      amount: amount,
      total: amount,
      tax: tax,
    })

    if (promoObj.id) {
      await loadCheckout({ promoObj, priceId: product.prices.id })
    } else {
      await loadCheckout({ promoObj: null, priceId: product.prices.id })
    }
  }

  useEffect(() => {
    function calculateDiscount() {
      if (!promoObj.id || paymentObj.amount < 0) {
        setPaymentObj(paymentObj => ({
          ...paymentObj,
          promotion_code: null,
          discountAmount: 0,
          total: paymentObj.amount,
          tax: paymentObj.amount * 25 * 0.01,
          discountAmountLbl: null
        }))

        return
      }

      let amount_off = null, percent_off = null, coupon = null, promotion_code = null
      if ("promotion_code" === promoObj.object) {
        promotion_code = promoObj.id // promotional code ID
        amount_off = promoObj.coupon.amount_off
        percent_off = promoObj.coupon.percent_off
      } else {
        // coupon object
        coupon = promoObj.id // coupon code ID
        amount_off = promoObj.amount_off
        percent_off = promoObj.percent_off
      }

      let discountAmount = -1
      let discountAmountLbl = promoObj.promoCode
      if (amount_off !== null && amount_off > 0) {
        discountAmount = parseFloat(amount_off / 100).toFixed(2)
        discountAmountLbl += ' (' + discountAmount + 'kr off)'

      } else if (percent_off !== null & percent_off > 0) {
        discountAmount = paymentObj.amount * percent_off * 0.01
        discountAmountLbl += ' (' + percent_off + '% off)'
      }

      if (discountAmount > -1) {
        discountAmount = parseFloat(discountAmount).toFixed(2)

        console.log('paymentObj', paymentObj)
        setPaymentObj(paymentObj => ({
          ...paymentObj,
          coupon: coupon,
          promotion_code: promotion_code,
          discountAmount,
          total: paymentObj.amount - discountAmount,
          tax: (paymentObj.amount - discountAmount) * 25 * 0.01,
          discountAmountLbl
        }))
      }
    }

    calculateDiscount()
    setIsDiscountCalculated(true)

  }, [isDiscountCalculated])


  return (
    <div className='plansPage'>
      {subscription && (
        <div style={{ paddingBottom: 16 }}>
          <p>
            {t('next_billing_date_txt')}:{' '}
            {new Date(
              subscription?.current_period_end * 1000
            ).toLocaleDateString(navigator.languages[0])}
          </p>
        </div>
      )}

      {products.length > 0 ? (
        <>
          {products
            .map(([productId, productData], index) => {
              const isCurrentPlan = productData.name.includes(subscription?.role)

              if (subscription?.items[0].plan.id === productData?.prices?.id) {
                return ''
              }

              if (productData?.prices?.id !== plans.year && productData?.prices?.id !== plans.month) {
                return ''
              }

              return (
                <div
                  key={productId}
                  className={`${isCurrentPlan ?
                    'plansPage__plan--disabled' : ''} planBox`}
                >
                  <button
                    className={`selectionBoxStyle ${productData?.prices?.id === newPlanId && 'active'}`}
                    onClick={() => { updateProduct(index) }}
                  >
                    {/* {isCurrentPlan && (<span className='productTrial active_plan'>{t('active_plan_txt')}</span>)} */}

                    {/* {productData?.prices?.data?.trial_period_days && (
                      <p className='productTrial plansPage' id={productId + '_trial'}>{t('product_trial_text', { count: productData.prices.data.trial_period_days })}</p>
                    )} */}

                    <h3 className='productName'>
                      {t(productData.name)}
                      {loadingCheckout && productData?.prices?.id === newPlanId && (
                        <div className="spinner" style={{ display: 'inline-block', marginLeft: '15px', verticalAlign: 'middle' }}></div>
                      )}
                    </h3>

                    <h5 id={productId + '_price'}>
                      {(
                        <p className='productDesc'>
                          <span>
                            {t(productData.prices.data.description)} <br></br>
                          </span>
                          <span>{t(productData.description)}</span>
                        </p>
                      )}
                    </h5>

                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      {productData?.prices?.id === newPlanId && (<circle cx="5" cy="5" r="5" fill="white"></circle>)}
                    </svg>
                  </button>
                </div>
              )
            })
          }

          <p></p>
          {switchPlan && (
            <>
              <form className='promoForm hide' onSubmit={verifyPromoCode}>
                <div className=''>
                  {/* promo code field */}
                  <input type='text' className='inputStyle' value={promoObj.promoCode} placeholder={t('Kampanjkod')} onChange={e => { setPromoObj(promoObj => ({ ...promoObj, promoCode: e.target.value })) }} required />

                  {/* Apply button */}
                  {!promoObj.id ? (
                    <button type='submit' className='buttonStyle'>
                      <span className='button-text'>
                        {isApplyingPromo ? <div className="spinner"></div> : t('coupon_apply_btn')}
                      </span>
                    </button>
                  ) : (
                    <button type='button' className='buttonStyle' onClick={removePromoCode}>{t('coupon_remove_btn')}</button>
                  )}
                </div>
              </form>

              <p></p>
              {newPlanId && (
                <table style={{ width: '100%' }}>
                  <tbody>
                    {paymentObj.product?.prices?.data.interval && (
                      <tr>
                        <td>{t('term')}</td>
                        <td align='right'>
                          {t(paymentObj.product?.prices?.data.interval)}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>{t('subtotal')}</td>
                      <td align='right'>
                        {paymentObj.amount > 0 ? paymentObj.amount : 0}kr
                      </td>
                    </tr>
                    {paymentObj.discountAmount > 0 && (
                      <tr>
                        <td>{paymentObj.discountAmountLbl}</td>
                        <td align='right'>
                          -{paymentObj.discountAmount}kr
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>{t('tax')} (25% {t('Inclusive')})</td>
                      <td align='right'>
                        {paymentObj.tax > 0 ? (
                          parseFloat(paymentObj.tax).toFixed(2) + 'kr'
                        ) : '0kr'}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('total')}</td>
                      <td align='right'>
                        {paymentObj.total > 0 ? paymentObj.total : 0}kr
                      </td>
                    </tr>

                    <tr><td colSpan="2"><p></p></td></tr>
                  </tbody>
                </table>
              )}
              <button className='buttonStyle' type='button' onClick={switchSubsPlan}>
                {isPlanSwitching ? (
                  <div className="spinner"></div>
                ) : (
                  t('switch_subs_btn')
                )}
              </button>
            </>
          )}

          {loadingCheckout && (
            <div style={{ display: "block", textAlign: "center" }}>
              {t('Please wait')}...
              <div className="spinner" style={{ display: 'inline-block', verticalAlign: 'middle' }}></div>
            </div>
          )}

          {
            error.message?.length > 0 && (
              <p className='errorMessage' style={error.success ? { backgroundColor: '#308f30' } : {}}>{t(error.message, { pass: error.pass })}</p>
            )
          }
        </>
      ) : (
        <div className="spinner"></div>
      )}
    </div>
  )
}

const PlansPage = withFirebase(PayBase)

const condition = authUser => !!authUser

export default compose(
  // withEmailVerification,
  withAuthorization(condition)
)(PlansPage)
