import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { AuthUserContext, withAuthorization } from '../Session'
import PlansPage from './PlansPage'
import { Trans, useTranslation } from 'react-i18next'
import { taxRates, ErrorsObj } from '../common'
import moment from 'moment';
import "moment/locale/sv";
import axios from 'axios'

const PaymentPage = ({ firebase }) => {
  const { t, i18n } = useTranslation()
  moment.locale(i18n.language);

  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [switchPlan, setSwitchPlan] = useState(false)
  const [promoObj, setPromoObj] = useState({ promoCode: '' })
  const [isApplyingPromo, setIsApplyingPromo] = useState(false)
  const [upcomingInvoice, setUpcomingInvoice] = useState(null)
  const [showUpcomingInvoice, setShowUpcomingInvoice] = useState(false)

  const [error, setError] = useState({})

  const currentUser = firebase.auth.currentUser

  const catchError = error => {
    console.log('error', error)

    if (!error.message) {
      error = error.json()
    }

    if (error.message === 'This coupon cannot be redeemed because it does not apply to anything in this order.') {
      error.code = 'stripe_cannot_reedemed'
    }

    if (ErrorsObj.hasOwnProperty(error.code)) {
      error.message = ErrorsObj[error.code]
    }

    setError(error)
  }

  useEffect(() => {
    const getUserSubscription = async ({ subscription, flag }) => {
      if (subscription) {
        if (subscription.current_period_start) {
          subscription.start_lbl = moment(subscription.current_period_start * 1000).format('MMM D, Y')
        }
        if (subscription.current_period_end) {
          subscription.end_lbl = ' - ' + moment(subscription.current_period_end * 1000).format('MMM D, Y')
        }
        if (subscription.trial_end) {
          subscription.trial_end = moment(subscription.trial_end * 1000).format('MMM D')
        }

        if (!subscription.invoice?.discount?.promotion_code) {
          subscription.invoice.discount = {
            coupon: {
              percent_off: null,
              amount_off: null
            }
          }
          subscription.invoice.promoObj = {}
          subscription.invoice.discount_lbl = null

        } else {
          subscription.promoObj = await getPromotion(subscription.invoice.discount.promotion_code)

          subscription.discount_lbl = subscription.promoObj?.code ?? subscription.invoice.discount.coupon.name
        }

        if (subscription.invoice.total_discount_amounts.length === 0) {
          subscription.discounted_amount = 0
        } else {
          subscription.discounted_amount = subscription.invoice.total_discount_amounts[0].amount
        }

        if (subscription.subscriptionId && subscription.invoice?.customer) {
          await getUpcomingInvoice(subscription)
        }

        setSubscription(subscription)
      }

      // set page load status
      if (flag === 'loaded') setIsPageLoaded(true)
    }

    getUserSubscription({ subscription: firebase.userSubscription, flag: firebase.userSubscriptionFlag })

  }, [firebase.userSubscriptionFlag])

  const getPromotion = async (id) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    // headers.append('Accept', 'application/json')

    // Create PaymentIntent as soon as the page loads
    return await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/getPromotion", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ id: id }),
    })
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(promotion => {
        /* setSubscription({
          ...subscription,
          promoObj: res.promotion
        }) */
        return promotion
      })
      .catch(error => { catchError(error); return {} })
  }

  const getUpcomingInvoice = async (subscription) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    // Create PaymentIntent as soon as the page loads
    await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/getUpcomingInvoice", {
      // mode: 'no-cors',
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        customerID: subscription.invoice.customer,
        subscriptionId: subscription.subscriptionId
      }),
    })
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(async upcomingInvoice => {
        // console.log('upcomingInvoice', upcomingInvoice, upcomingInvoice.subtotal)
        if (!upcomingInvoice.discount?.promotion_code) {
          upcomingInvoice.discount = {
            coupon: {
              percent_off: null,
              amount_off: null
            }
          }
          upcomingInvoice.promoObj = {}
          upcomingInvoice.discount_lbl = null
        } else {
          upcomingInvoice.promoObj = await getPromotion(upcomingInvoice.discount.promotion_code)

          upcomingInvoice.discount_lbl = upcomingInvoice.promoObj?.code ?? upcomingInvoice.discount.coupon.name
        }

        if (upcomingInvoice.total_discount_amounts.length === 0) {
          upcomingInvoice.discounted_amount = 0
        } else {
          upcomingInvoice.discounted_amount = upcomingInvoice.total_discount_amounts[0].amount
        }

        // console.log('upcoming updated invoice', invoice)

        setUpcomingInvoice(upcomingInvoice)
      })
      .catch(error => catchError(error))

  }

  /**
   * Add promo for make discount in next billing cycle of subscription payment
   */
  const updateSubscription = async () => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    const obj = {
      subscriptionId: subscription.subscriptionId,
      promotion_code: promoObj.id,
      taxRates: taxRates
    }
    console.log('update Subsription obj', obj)

    await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/updateSubscription", {
      // mode: 'no-cors',
      method: "POST",
      headers: headers,
      body: JSON.stringify(obj),
    })
      .then((res) => {
        setIsApplyingPromo(false)

        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(async (res) => {
        console.log(res)
        setError({
          ...error,
          message: 'subs_promocode_applied_msg',
          success: true
        })

        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch(error => catchError(error))
  }

  const loadCheckout = async (priceId) => {
    if (!currentUser.email) return;
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/createCheckoutSession", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        price: priceId,
        success_url: window.location.origin + '/payment',
        cancel_url: window.location.origin + '/payment',
        promotion_code: promoObj.id,
        customer_email: currentUser.email
      }),
    })
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(async (res) => {
        // data.id = checkout session id
        // data.url = checkout session url, where user can pay directly
        // data.amount_total = checkout session amount which one needs to pay via user
        console.log('session obj', res)

        if (res.id) {
          /* setTimeout(() => {
            document.getElementById('updateSubscription').click()
          }, 50) */
          await updateSubscription();
        }
      })
      .catch(error => {
        setIsApplyingPromo(false)
        catchError(error)
      })
  }

  const verifyPromoCode = async (e) => {
    e.preventDefault()

    setIsApplyingPromo(true)

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    // headers.append('Accept', 'application/json')

    // Create PaymentIntent as soon as the page loads
    await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/verifyPromoCode", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ promoCode: promoObj.promoCode }),
    })
      .then((res) => {
        if (res.ok === false) {
          return Promise.reject(res)
        }
        return res.json()
      })
      .then(async res => {
        console.log(res)
        setPromoObj(promoObj => ({
          ...promoObj,
          ...res.data,
        }))

        setTimeout(() => {
          document.getElementById('updateSubscription').click()
        }, 50)
      })
      .catch(error => {
        setIsApplyingPromo(false)
        catchError(error)
      })
  }

  const redirectCustomerStripePortal = async () => {
    // Call billing portal function
    /* const functionRef = await firebase.app
      .functions('europe-west1')
      .httpsCallable(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_ENDPOINT)

    await functionRef({ returnUrl: window.location.origin + '/account' })
      .then(({ data }) => window.location.assign(data.url))
      .catch(err => { console.log(err); catchError(err) }) */

    if (subscription.customer) {
      try {
        const config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_SERVER_API_URL}/stripe/getPortalLink`,
          headers: {
            "X-API-KEY": `${process.env.REACT_APP_SERVER_API_KEY}`,
          },
          data: {
            email: currentUser.email,
            returnUrl: window.location.origin + '/account'
          },
        };
        const response = await axios.request(config);

        if (response.data.url) {
          window.location.assign(response.data.url)
        } else {
          setError({ message: "Request failed" });
        }
      } catch (error) {
        if (error.response?.data?.message) {
          setError({ message: error.response.data.message });
        } else {
          setError({ message: error.message });
        }
      }
    }
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className='accountBox'>
          <div className='accountContent'>
            <h1 className='sectionTitle'>{t('manage_subs_page_head')}</h1>

            {isPageLoaded ? (
              <div className='pageLoaded'>
                {subscription ? (
                  <div>
                    <p style={{ borderBottom: '1px solid', paddingBottom: '10px' }}>
                      {t('current_plan_heading')}
                    </p>

                    <div className='currentPlans'>
                      {subscription?.items?.map(item => {
                        return (
                          <table key={item.id} style={{ width: '100%', textAlign: 'right' }}>
                            <tbody>
                              <tr>
                                <td align="left">
                                  <h3 style={{ marginTop: 0, position: 'relative' }}>
                                    {item.price.product.name}
                                    {subscription.status === 'trialing' && (
                                      <span className='trialStatus'>{t('trial_ends', { date: subscription.trial_end })}</span>
                                    )}
                                    <br />
                                    <small style={{ fontSize: '13px' }}>
                                      {subscription.start_lbl}
                                      {subscription.end_lbl}
                                    </small>
                                    {/* <small>({parseFloat((subscription.invoice.total - (subscription.invoice.ending_balance > 0 && 0 )) / 100).toFixed(2)}kr/{item.plan.interval})</small> */}
                                  </h3>
                                </td>
                                <td style={{ position: 'relative' }}>
                                  {subscription.invoice?.invoice_pdf && (
                                    <small style={{ position: 'absolute', top: '3px', right: '0', fontSize: '13px' }}><a href={subscription.invoice.invoice_pdf} target="blank">{t('download_invoice_btn')}</a></small>
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>{t('subscription_amount')}</td>
                                <td>{parseFloat(item.price.unit_amount / 100).toFixed(2)}kr</td>
                              </tr>

                              {subscription.invoice?.ending_balance < 0 && (
                                <tr>
                                  <td>{subscription.invoice.lines.data[0].description}</td>
                                  <td>{parseFloat(subscription.invoice.lines.data[0].amount / 100).toFixed(2)}kr</td>
                                </tr>
                              )}

                              <tr>
                                <td>{t('subtotal')}</td>
                                <td>{parseFloat(subscription.invoice.subtotal / 100).toFixed(2)}kr</td>
                              </tr>

                              {subscription.discount_lbl && (
                                <tr>
                                  <td>
                                    {subscription.discount_lbl}{' '}

                                    (
                                    {/* percent_off */}
                                    {subscription.invoice.discount.coupon.percent_off ? subscription.invoice.discount.coupon.percent_off + '%' : ''}

                                    {/* amount_off */}
                                    {subscription.invoice.discount.coupon.amount_off ? parseFloat(subscription.invoice.discount.coupon.amount_off / 100).toFixed(2) + 'kr' : ''}
                                    )
                                  </td>
                                  <td>
                                    {subscription.discounted_amount > 0 && ('-')}
                                    {parseFloat(subscription.discounted_amount / 100).toFixed(2)}kr
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td>{t('total_excluding_tax')}</td>
                                <td>{parseFloat(subscription.invoice.total_excluding_tax / 100).toFixed(2)}kr</td>
                              </tr>

                              {/* check default_tax_rates available */}
                              {subscription.invoice.default_tax_rates.length > 0 ? (
                                <tr>
                                  <td>{t(
                                    (subscription.invoice.default_tax_rates[0].inclusive ? 'tax_line_incl' : 'tax_line_excl'),
                                    {
                                      tax: subscription.invoice.default_tax_rates[0].percentage + '%',
                                      amount: parseFloat(subscription.invoice.total_excluding_tax / 100).toFixed(2) + 'kr'
                                    }
                                  )}</td>
                                  <td>{parseFloat(subscription.invoice.tax / 100).toFixed(2)}kr</td>
                                </tr>
                              ) : (
                                // if default_tax_rates not available then check total_tax_amounts available ?
                                subscription.invoice.total_tax_amounts.length > 0 ? (
                                  subscription.invoice.total_tax_amounts.map((tax_amount, ii) => {
                                    return (
                                      <tr key={ii}>
                                        <td>{tax_amount.amount && tax_amount.taxable_amount ? t(
                                          (tax_amount.inclusive ? 'tax_line_incl' : 'tax_line_excl'),
                                          {
                                            tax: (tax_amount.amount / tax_amount.taxable_amount * 100).toFixed() + '%',
                                            amount: parseFloat(tax_amount.taxable_amount / 100).toFixed(2) + 'kr'
                                          }
                                        ) : t('tax')}</td>
                                        <td>{tax_amount.amount ? parseFloat(tax_amount.amount / 100).toFixed(2) : '0.00'}kr</td>
                                      </tr>
                                    )
                                  })
                                ) : (
                                  // if both not available
                                  <tr>
                                    <td>{t('tax')}</td>
                                    <td>0.00kr</td>
                                  </tr>
                                )
                              )}

                              <tr>
                                <td>{t('total')}</td>
                                <td>{parseFloat(subscription.invoice.total / 100).toFixed(2)}kr</td>
                              </tr>

                              {subscription.invoice?.ending_balance < 0 && (
                                <tr>
                                  <td>{t('Applied balance')}</td>
                                  <td>{parseFloat((subscription.invoice.ending_balance * -1) / 100).toFixed(2)}kr</td>
                                </tr>
                              )}
                              {subscription.invoice?.starting_balance > 0 && (
                                <tr>
                                  <td>{t('Applied balance')}</td>
                                  <td>{parseFloat(subscription.invoice.starting_balance / 100).toFixed(2)}kr</td>
                                </tr>
                              )}
                              {subscription.invoice?.amount_due > 0 && subscription.invoice.amount_due !== subscription.invoice.amount_paid && (
                                <tr>
                                  <td>{t('amount_due')}</td>
                                  <td>{parseFloat(subscription.invoice.amount_due / 100).toFixed(2)}kr</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )
                      })}
                    </div>

                    <p>&nbsp;</p>

                    {upcomingInvoice ? (
                      <section>
                        <p>
                          {t('next_billing_date_txt')}:{''} {moment(new Date(
                            subscription?.current_period_end * 1000
                          )).format('MMM D, Y')}

                          {' '}

                          <small><button type='button' style={{
                            color: '#9e0145',
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                            fontSize: '16px',
                            padding: 0,
                            textDecoration: 'underline'
                          }} onClick={e => setShowUpcomingInvoice(!showUpcomingInvoice)}>({!showUpcomingInvoice ? t('See next billing detail') : t('Hide next billing detail')})</button></small>
                        </p>

                        {showUpcomingInvoice && (
                          <div className='upcomingInvoice' style={{ borderTop: '1px solid #9e0145', paddingTop: '20px' }}>
                            {subscription.items.map(item => {
                              return (
                                <table key={item.id} style={{ width: '100%', textAlign: 'right' }}>
                                  <tbody>
                                    <tr>
                                      <td colSpan="2" align="left">
                                        <h3 style={{ marginTop: 0 }}>
                                          {item.price.product.name} {' '}
                                          {/* <small>({parseFloat(upcomingInvoice.total / 100).toFixed(2)}kr/{item.plan.interval})</small> */}
                                        </h3>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>{t('Subscription Amount')}</td>
                                      <td>{parseFloat(item.price.unit_amount / 100).toFixed(2)}kr</td>
                                    </tr>

                                    <tr>
                                      <td>{t('subtotal')}</td>
                                      <td>{parseFloat(upcomingInvoice.subtotal / 100).toFixed(2)}kr</td>
                                    </tr>

                                    {upcomingInvoice.discount_lbl && (
                                      <tr>
                                        <td>{upcomingInvoice.discount_lbl}{' '}
                                          (
                                          {upcomingInvoice.discount.coupon.percent_off ? upcomingInvoice.discount.coupon.percent_off + '%' : ''}

                                          {upcomingInvoice.discount.coupon.amount_off ? parseFloat(upcomingInvoice.discount.coupon.amount_off / 100).toFixed(2) + 'kr' : ''}
                                          )
                                        </td>
                                        <td>{upcomingInvoice.discounted_amount > 0 && ('-')}{parseFloat(upcomingInvoice.discounted_amount / 100).toFixed(2)}kr</td>
                                      </tr>
                                    )}

                                    <tr>
                                      <td>{t('total_excluding_tax')}</td>
                                      <td>{parseFloat(upcomingInvoice.total_excluding_tax / 100).toFixed(2)}kr</td>
                                    </tr>

                                    {
                                      upcomingInvoice.default_tax_rates.length > 0 ? (
                                        <tr>
                                          <td>{t(
                                            (upcomingInvoice.default_tax_rates[0].inclusive ? 'tax_line_incl' : 'tax_line_excl'),
                                            {
                                              tax: upcomingInvoice.default_tax_rates[0].percentage + '%',
                                              amount: parseFloat(upcomingInvoice.total_excluding_tax / 100).toFixed(2) + 'kr'
                                            }
                                          )}</td>
                                          <td>{parseFloat(upcomingInvoice.tax / 100).toFixed(2)}kr</td>
                                        </tr>
                                      ) : (
                                        upcomingInvoice.total_tax_amounts.length > 0 ? (
                                          upcomingInvoice.total_tax_amounts.map((tax_amount, ii) => {
                                            return (
                                              <tr key={ii}>
                                                <td>{tax_amount.amount && tax_amount.taxable_amount ? t(
                                                  (tax_amount.inclusive ? 'tax_line_incl' : 'tax_line_excl'),
                                                  {
                                                    tax: (tax_amount.amount / tax_amount.taxable_amount * 100).toFixed() + '%',
                                                    amount: parseFloat(tax_amount.taxable_amount / 100).toFixed(2) + 'kr'
                                                  }
                                                ) : t('tax')}</td>
                                                <td>{tax_amount.amount ? parseFloat(tax_amount.amount / 100).toFixed(2) : '0.00'}kr</td>
                                              </tr>
                                            )
                                          })
                                        ) : (
                                          <tr>
                                            <td>{t('tax')}</td>
                                            <td>0.00kr</td>
                                          </tr>
                                        )
                                      )
                                    }

                                    <tr>
                                      <td>{t('total')}</td>
                                      <td>{parseFloat(upcomingInvoice.total / 100).toFixed(2)}kr</td>
                                    </tr>

                                    {upcomingInvoice.amount_due > 0 && upcomingInvoice.amount_due !== upcomingInvoice.amount_paid && (
                                      <tr>
                                        <td>{t('amount_due')}</td>
                                        <td>{parseFloat(upcomingInvoice.amount_due / 100).toFixed(2)}kr</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              )
                            })}
                          </div>
                        )}
                      </section>
                    ) : (
                      <p>
                        {t('subscription_period_end_txt')}:{''} {new Date(
                          subscription?.current_period_end * 1000
                        ).toLocaleDateString(navigator.languages[0])}
                      </p>
                    )}

                    <p>&nbsp;</p>
                    <p>
                      <small>
                        {switchPlan ? (
                          <span style={{ display: "block", textAlign: "center" }}>
                            {t('Please wait')}...
                            <span className="spinner" style={{ display: 'inline-block', verticalAlign: 'middle' }}></span>
                          </span>
                        ) : (
                          <Trans i18nKey="subs_switch_plan_txt">
                            If you want to upgrade/downgrade your plan, <button type='button' style={{
                              color: '#9e0145',
                              cursor: 'pointer',
                              background: 'none',
                              border: 'none',
                              fontSize: '16px',
                              padding: 0,
                              textDecoration: 'underline',
                            }} onClick={e => { setSwitchPlan(true); redirectCustomerStripePortal(); }}>update plan</button>.
                          </Trans>
                        )}
                      </small>
                    </p>

                    <p>&nbsp;</p>
                    <p style={{ borderBottom: '1px solid', paddingBottom: '10px' }}>
                      {t('subs_promocode_form_head')}
                    </p>

                    <form className='promoForm' onSubmit={verifyPromoCode}>
                      <button id="updateSubscription" type='button' onClick={e => loadCheckout(subscription.items[0].price.id)} style={{ display: 'none' }}>updateSubscription</button>

                      <div className=''>
                        {/* promo code field */}
                        <input type='text' className='inputStyle' value={promoObj.promoCode} placeholder={t('Kampanjkod')} onChange={e => { setPromoObj(promoObj => ({ ...promoObj, promoCode: e.target.value })) }} required />

                        {/* Apply button */}
                        <button type='submit' className='buttonStyle'>
                          <span className='button-text'>
                            {isApplyingPromo ? <div className="spinner"></div> : t('coupon_apply_btn')}
                          </span>
                        </button>
                      </div>
                    </form>

                    {error.message?.length > 0 && (
                      <p className='errorMessage' style={error.success ? { backgroundColor: '#308f30' } : {}}>{t(error.message, { pass: error.pass })}</p>
                    )}
                  </div>
                ) : (
                  <div>
                    <p>{t('Kom och låt våra stories förföra dig')}</p>
                    <PlansPage authUser={authUser} switchPlan={switchPlan} />
                  </div>
                )}
              </div>
            ) : (
              <div className="pageLoading spinner"></div>
            )}
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser

export default compose(withAuthorization(condition))(PaymentPage)
