export const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
    name: 'lössenord'
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
    name: 'Google'
  },
  {
    id: 'facebook.com',
    provider: 'facebookProvider',
    name: 'Facebook'
  }
  // {
  //   id: 'twitter.com',
  //   provider: 'twitterProvider',
  // },
]
