import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { compose } from 'recompose'

import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

import { useTranslation } from 'react-i18next'

const withAuthorization = condition => Component => {

  const WithAuthorization = props => {
    const { i18n } = useTranslation()
    const baseUrl = i18n.language === 'sv' ? '' : '/' + i18n.language;

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      const unsubscribe = props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            navigate(baseUrl + ROUTES.THANKYOU)
          }
        },
        () => {
          navigate(baseUrl + ROUTES.SIGN_IN + `/?redirectTo=${location.pathname}${location.search}`)
        }
      )
      return () => unsubscribe()
    })

    return (
      <AuthUserContext.Consumer>
        {authUser => (condition(authUser) ? <Component {...props} /> : null)}
      </AuthUserContext.Consumer>
    )
  }

  const withRouter = Component => props => {
    const navigate = useNavigate()
    const location = useLocation()

    return <Component {...props} navigate={navigate} location={location} />
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}

export default withAuthorization
