import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { BlancheLogoRed } from "../Icons";
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../Navigation";

import { buttonLinkStyle } from "../common";

import axios from "axios";
import moment from "moment";
import "moment/locale/sv";

const GiftCardConfirm = (props) => {
  const { t, i18n } = useTranslation();
  // const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;
  moment.locale(i18n.language);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    email: "",
    uid: "",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [giftCardData, setGiftCardData] = useState({});

  useEffect(() => {
    let isMounted = true;

    const localData = window.sessionStorage.getItem("giftCardDetails");
    if (localData) {
      setGiftCardData(JSON.parse(localData));
    } else {
      navigate(ROUTES.Gift_card_redeem);
    }

    if (props.firebase.auth.currentUser && isMounted) {
      userInfo.uid = props.firebase.auth.currentUser.uid;
      userInfo.email = props.firebase.auth.currentUser.email;
      setUserInfo(userInfo);
    }

    return () => {
      isMounted = false;
    };
  }, [props.firebase.auth.currentUser, userInfo, navigate]);

  const confirmRedeem = async () => {
    if (!userInfo.uid || !giftCardData.code) return;

    setIsLoading(true);

    try {
      const config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SERVER_API_URL}/stripe/confirmRedeem`,
        headers: {
          "X-API-KEY": `${process.env.REACT_APP_SERVER_API_KEY}`,
        },
        data: {
          userId: userInfo.uid,
          giftCardSpecialNumber: giftCardData.code,
        },
      };
      const response = await axios.request(config);

      if (response.data.success) {
        // window.sessionStorage.removeItem("giftCardDetails");
        navigate(ROUTES.THANKYOU);
      } else {
        setError({ message: "Request failed" });
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setError({ message: error.response.data.message });
      } else {
        setError({ message: error.message });
      }
    }

    setIsLoading(false); // hide loader
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="landingPage newTemplate">
          {!authUser && (
            <div className="centerContent logoSection">
              <div style={{ width: "100%" }}>
                <BlancheLogoRed className="logo" />

                <LanguageSwitcher list={true} />
              </div>
            </div>
          )}

          <div className="centerContent">
            <div
              className="sectionContent"
              style={{
                maxWidth: "90%",
                width: "600px",
                border: "3px solid #9f1c44",
                padding: "50px",
              }}
            >
              <div style={{ width: "100%", marginBottom: "50px" }}>
                <h2
                  className=""
                  style={{
                    fontSize: "31px",
                    fontWeight: "normal",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {t("Redeem a gift card review")}
                </h2>

                <p
                  className=""
                  style={{
                    fontSize: "23px",
                    fontWeight: 300,
                    textAlign: "left",
                    width: "100%",
                    marginBottom: "5px",
                  }}
                >
                  <strong style={{ fontWeight: 400 }}>{t("Plan")}:</strong>{" "}
                  {(giftCardData.product &&
                    (i18n.language === "sv"
                      ? giftCardData.product.swedishName
                      : giftCardData.product.name)) ||
                    ""}
                </p>

                <p
                  className=""
                  style={{
                    fontSize: "23px",
                    fontWeight: 300,
                    textAlign: "left",
                    width: "100%",
                    marginBottom: "5px",
                  }}
                >
                  <strong style={{ fontWeight: 400 }}>
                    {t("Plan duration until")}:
                  </strong>{" "}
                  {(giftCardData.product &&
                    moment()
                      .add(giftCardData.product.duration_days, "days")
                      .format("MMM D, Y")) ||
                    ""}
                </p>

                <p
                  className=""
                  style={{
                    fontSize: "23px",
                    fontWeight: 300,
                    textAlign: "left",
                    width: "100%",
                    marginBottom: "5px",
                  }}
                >
                  <strong style={{ fontWeight: 400 }}>{t("Account")}:</strong>{" "}
                  {userInfo.email}
                </p>

                {error && (
                  <>
                    <p></p>
                    <p
                      className="errorMessage"
                      style={{
                        fontSize: "18px",
                        background: "transparent",
                        padding: 0,
                        color: (error.success && "green") || "#9f1c44",
                      }}
                    >
                      {error.message}
                    </p>
                  </>
                )}

                <p>&nbsp;</p>

                <button
                  type="button"
                  style={{ ...buttonLinkStyle }}
                  onClick={() => confirmRedeem()}
                  disabled={isLoading}
                >
                  <span className="button-text">
                    {(isLoading && <div className="spinner"></div>) ||
                      t("Confirm the redeem")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(GiftCardConfirm);
