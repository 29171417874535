import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import { BlancheLogoRed } from "../Icons";
import { LanguageSwitcher } from "../Navigation";
import { useTranslation } from "react-i18next";
import { linkStyle } from "../common";

const PasswordForgetPage = () => {
  const { t } = useTranslation();

  return (
    <div className="landingPage signin-page">
      <div className="centerContent logoSection">
        <div style={{ width: "100%" }}>
          <BlancheLogoRed className="logo" />

          <LanguageSwitcher list={true} />
        </div>
      </div>

      <div className="centerContent">
        <div className="sectionContent">
          <h1 className="sectionTitle">{t("passw_forget_page_heading")}</h1>
          <PasswordForgetForm />
        </div>
      </div>
    </div>
  );
};

const PasswordForgetFormBase = (props) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onSubmit = (event) => {
    console.log("going to send", email);

    props.firebase
      .doPasswordReset(email)
      .then(() => {
        console.log(email);
        setEmail(email);
        setError(null);
        setSent(true);
        console.log(sent);
      })
      .catch((error) => {
        setError(error);
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    const { value } = event.target;
    console.log(value);
    setEmail(value);
  };

  //const isInvalid = email === '';

  const { search } = useLocation();

  useEffect(() => {
    const firstEmail = new URLSearchParams(search).get("email");
    if (!sent) {
      document.getElementById("emailInput").value = firstEmail;
      setEmail(firstEmail);
    }
  }, [search, sent]);

  const goBack = () => {
    navigate(baseUrl + ROUTES.SIGN_IN);
  };

  return (
    <>
      {sent ? (
        <div>
          <p>
            {t("Ett mejl har skickats med anvisningar till")} <br />
            {email}
          </p>
          <button type="submit" className="buttonStyle" onClick={goBack}>
            {t("Tillbaka")}
          </button>
        </div>
      ) : (
        <div>
          <p>
            {t("Fyll i din e-post nedan och få ett mejl med anvisningar")}
            <br></br>
            {t("för hur du återställer ditt lösenord")}
          </p>
          <form onSubmit={onSubmit}>
            <input
              id="emailInput"
              name="email"
              value={email}
              onChange={onChange}
              type="text"
              placeholder={t("email_lbl")}
              className="inputStyle"
            />
            <button type="submit" className="buttonStyle">
              {t("send_btn")}
            </button>

            {error && <p className="errorMessage">{t(error.message)}</p>}
          </form>
        </div>
      )}
    </>
  );
};

const PasswordForgetLink = (props) => {
  const { t, i18n } = useTranslation();
  const baseUrl = i18n.language === "sv" ? "" : "/" + i18n.language;

  return (
    <p>
      {t("Glömt lösenordet?")}{" "}
      <Link
        to={`${baseUrl + ROUTES.PASSWORD_FORGET}?email=${props.email}`}
        style={linkStyle}
      >
        {t("Vi hjälper dig här")}{" "}
      </Link>
    </p>
  );
};

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
